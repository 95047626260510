import { EntityModel } from "hateoas-hal-types";
import { Cache } from "swr";
import useRequest, { mutate, post, prefetch, put, useSSE } from "../api";
import { Chat, ChatDocuments } from "../models/Chat.model";
import DocumentStatus from "../models/DocumentStatus.enum";
import RefData from "../models/RefData.model";

const CHATS_BASE_URL = "/chats";

export const useChats = (type: "recent" | "saved" | "focused" | "last-opened", query?: string) =>
  useRequest<EntityModel<Chat>[]>(
    `${CHATS_BASE_URL}/${type}${query ? `?query=${query}` : ""}`,
    (data) => data?._embedded?.items || []
  );

export const useChat = (id?: string) => {
  let { data } = useRequest<EntityModel<Chat>>(id ? `${CHATS_BASE_URL}/${id}` : null);
  return data;
};

export const useChatDocuments = (id?: string) => {
  let { data } = useRequest<EntityModel<ChatDocuments>>(
    id ? `${CHATS_BASE_URL}/${id}/documents` : null
  );
  return data;
};

export const prefetchChat = (id: string, cache: Cache<any>) => {
  return prefetch(`${CHATS_BASE_URL}/${id}`, cache);
};

export type StartChatInput = { query: string; sourceId?: string; quick?: boolean };

export const startChat = async (data: StartChatInput) => {
  const result = (await post(`${CHATS_BASE_URL}`, data)) as EntityModel<Chat>;

  setTimeout(async () => {
    await mutate(`${CHATS_BASE_URL}/recent`, () => {}, true);
  }, 1000);
  await mutate(`${CHATS_BASE_URL}/${result.id}`, () => result, false);
  await mutate(`/user/current/quotas`);
  return result;
};

export const updateChat = async (chatId: string, data: { query: string; sourceId?: string }) => {
  const result = (await put(`${CHATS_BASE_URL}/${chatId}`, data)) as EntityModel<Chat>;
  setTimeout(async () => {
    await mutate(`${CHATS_BASE_URL}/recent`, () => {}, true);
  }, 1000);
  await mutate(`${CHATS_BASE_URL}/${chatId}`, () => result, false);
  await mutate(`/user/current/quotas`);
  return result;
};

export const updateChatStatus = async (chatId: string, data: DocumentStatus) => {
  const result = (await put(`${CHATS_BASE_URL}/${chatId}/status`, data, true)) as EntityModel<Chat>;
  await mutate(
    `${CHATS_BASE_URL}/${chatId}`,
    (current: Chat) => {
      const updated = { ...current };
      updated.status = data;
      return updated;
    },
    false
  );
  return result;
};

export const regenerateResponse = async (chatId: string, data: RefData) => {
  const result = (await post(`${CHATS_BASE_URL}/${chatId}/responses`, data)) as EntityModel<Chat>;
  await mutate(`${CHATS_BASE_URL}/${chatId}`, () => result, false);
  await mutate(`/user/current/quotas`);
  return result;
};

// export const useResponseContents = (chatId: string, responseId: string) => {
//   return useRequest<{ data: string; status: "receiving" | "completed" }>(
//     `${CHATS_BASE_URL}/${chatId}/responses/${responseId}/content`,
//     (data) => data,
//     {
//       refreshInterval: 500,
//     }
//   );
// };

export const useResponseContents = (chatId: string, responseId: string) => {
  return useSSE<{ data: string; status: "receiving" | "ok" | "error" }>(
    `${CHATS_BASE_URL}/${chatId}/responses/${responseId}/content`
  );
};
