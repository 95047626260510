import { FormattedMessage, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { UseSearchRequestReturn } from "../../../api";
import LabelValue from "../../../components/label-value";
import { InlineLink } from "../../../components/Link/link";
import PreloadingLink from "../../../components/PreloadingLink";
import { Privileges, useAuth } from "../../../contexts/auth.context";
import { useSearchContext } from "../../../contexts/search.context";
import { useSelectedActContext } from "../../../contexts/selected-act.context";
import { Selection, UseMultipleSelectionReturn } from "../../../hooks/useMultipleSelection";
import { JudgementAct } from "../../../models/JudgementAct.model";
import {
  addTopicToAct,
  fetchJudgementAct,
  fetchJudgementActPdf,
  judgementActHref,
  prefetchJudgementAct,
  removeTopicFromAct,
  useConstJudgementActs,
  useJudgementActs,
  useSupremeCourtudgementActs,
} from "../../../services/judgement-act-services";
import { summaryHref } from "../../../services/summary-services";
import ResultsTable from "../ResultsTable/results-table";
import ResultsTableDocumentCollectionMenuActions from "../ResultsTable/results-table-document-collection-actions";
import ResultsTableHeader from "../ResultsTable/results-table-header";
import ChangeTopicOfDocument from "./change-topic-of-document.modal";

export const SccJudgementActsResults = () => {
  const results = useJudgementActs();
  return <JudgementActsResults results={results} />;
};

export const ScJudgementActsResults = () => {
  const results = useSupremeCourtudgementActs();
  return <JudgementActsResults results={results} />;
};

export const CsJudgementActsResults = () => {
  const results = useConstJudgementActs();
  return <JudgementActsResults results={results} />;
};

const JudgementActsResults: React.FC<{ results: UseSearchRequestReturn<JudgementAct> }> = ({
  results,
}) => {
  const auth = useAuth();
  const { formatMessage } = useIntl();

  const { opened, addOpened } = useSearchContext();

  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");

  const { setSelectedActId } = useSelectedActContext();

  return (
    <ResultsTable
      results={results}
      header={(params) => (
        <ResultsTableHeader {...params} actions={<JudgementActsActionsMenu {...params} />} />
      )}
      itemToSelection={itemToSelection}
      allowBulkLoad={auth.hasPrivilige(Privileges.EDIT_SUMMARIES)}
      row={(item) => (
        <ResultsTable.ResultTableRow>
          <PreloadingLink
            to={judgementActHref(item.id) + (q ? `?q=${q}` : "")}
            state={{ title: item.title }}
            preload={(cache) => prefetchJudgementAct(item.id + (q ? `?q=${q}` : ""), cache)}
            onClick={() => addOpened(item.id)}
            className={opened.indexOf(item.id) > -1 ? "visited" : ""}
          >
            {item.title}
          </PreloadingLink>
          {item.keywords && <div>{item.keywords}</div>}
          {item.reporters?.length > 0 && (
            <LabelValue label={formatMessage({ id: "view-judgement.labels.reporter" })}>
              {item.reporters?.join(", ")}
            </LabelValue>
          )}
          {item.caseType &&
            item.caseType.code !== "CRIMINAL" &&
            item.caseType.code !== "CRIMINAL_PRIVATE" && (
              <LabelValue label={formatMessage({ id: "view-judgement.labels.proceedingBy" })}>
                {item.proceeding?.shortName}
              </LabelValue>
            )}

          {item.summaries?.length > 0 && (
            <LabelValue label={formatMessage({ id: "view-judgement.labels.summary" })}>
              {item.summaries!.map((summary) => (
                <InlineLink
                  key={summary.id}
                  to={summaryHref(summary.id)}
                  onClick={(e) => {
                    if (!e.ctrlKey && !e.metaKey) {
                      e.preventDefault();
                      setSelectedActId("summary:" + summary.id);
                    }
                  }}
                >
                  {summary.shortTitle || <FormattedMessage id="label.link" />}
                </InlineLink>
              ))}
            </LabelValue>
          )}
          <ResultsTable.RowIndicators item={item} />
          <ResultsTable.RowHighlights item={item} />
        </ResultsTable.ResultTableRow>
      )}
    />
  );
};

export const JudgementActsActionsMenu: React.FC<UseMultipleSelectionReturn> = (props) => (
  <>
    <ChangeTopicOfDocument {...props} action={addTopicToAct} actionType="add" />
    <ChangeTopicOfDocument {...props} action={removeTopicFromAct} actionType="remove" />
    <ResultsTableDocumentCollectionMenuActions
      {...props}
      printExportHandler={async (first) => {
        const act = await fetchJudgementAct(first._id);
        return {
          contents: (await fetchJudgementActPdf(act!.id, act!.domain)).data,
          fileName: `${act.title}.pdf`,
        };
      }}
    />
  </>
);

const itemToSelection = (item: JudgementAct): Selection => ({
  _id: item.id,
  title: item.title,
  href: judgementActHref(item.id),
  hasSummary: item.summaries.length > 0,
  hasAnnotations: item.hasAnnotations,
  hasEditorAnnotation: item.hasEditorAnnotation,
  hasAiAnnotations: item.hasAiAnnotations,
});
