import { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import { useTitle } from "react-use";
import { ActionButton } from "../../components/Button/button";
import { ManageScroll } from "../../components/ManageScroll";
import {
  isLogged,
  Privileges,
  useAuth,
  Visible,
  withPublicAccess,
} from "../../contexts/auth.context";
import useRemindExpireSoon from "../../hooks/useRemindExpireSoon";
import NewsArticleSection from "../../models/NewsArticleSection.enum";
import SummaryTopic from "../../models/SummaryTopic.model";
import SubscribeTopicModal from "../SearchPage/SearchLanding/subscribe-topic.modal";
import DailyBulletin from "./daily-bulletin";
import FocusedChats from "./focused-chats";
import FocusedJudgementActs from "./focused-judgement-acts";
import FocusedNewsArticles from "./focused-news-articles";
import FocusedSection from "./focused-section";
import HighlightedNewsArticles from "./highlighted-news-articles";
import HomePageUsers from "./home-page-users";
import "./home-page.css";
import Timeline from "./timeline";
import TimelineTopicSelect from "./timeline-topic-select";
const HomePage = () => {
  const { state } = useLocation();
  const [selectedTopic, setSelectedTopic] = useState<SummaryTopic | undefined>(
    state?.selectedTopic
  );
  const intl = useIntl();
  const navigate = useNavigate();
  useTitle("Начало");
  useRemindExpireSoon();

  const auth = useAuth();
  const accessToAskLexebra = auth.hasPrivilige(Privileges.EDIT_SUMMARIES);

  if (!accessToAskLexebra) {
    return <HomePageUsers />;
  }

  return (
    <div className="home-page">
      <ManageScroll />
      <HighlightedNewsArticles />
      <Container fluid className="home-container">
        <Row className="focused-section-container">
          <Col md="6" xs="12">
            <FocusedSection title={intl.formatMessage({ id: "home-page.sections.ask-lexebra" })}>
              <FocusedChats />
            </FocusedSection>
          </Col>
          <Col md="6" xs="12">
            <FocusedSection
              title={intl.formatMessage({ id: "home-page.sections.focused-judgement-acts" })}
            >
              <FocusedJudgementActs />
            </FocusedSection>
            <FocusedSection title={intl.formatMessage({ id: "home-page.sections.focused-news" })}>
              <FocusedNewsArticles section={NewsArticleSection.NEWS} />
            </FocusedSection>
          </Col>
        </Row>

        <Row>
          <Col md="6" xs="12">
            <ActionButton
              onClick={() => navigate(`/ask-lexebra`)}
              submitting={false}
              style={{ width: "100%", justifyContent: "center" }}
            >
              {intl.formatMessage({ id: "home-page.sections.new-question" })}
            </ActionButton>
          </Col>
          <Col md="6" xs="12">
            <DailyBulletin />
          </Col>
        </Row>
        <div className="timeline-container">
          <div className="timeline-header">
            <span className="timeline-header-title">
              <FormattedMessage id="home-page.sections.my-topics" />
              <Visible when={isLogged()}>
                {" "}
                <SubscribeTopicModal />
              </Visible>
            </span>
            <div className="timeline-header-content">
              <Visible when={isLogged()}>
                <TimelineTopicSelect
                  selectedTopic={selectedTopic}
                  setSelectedTopic={setSelectedTopic}
                />
              </Visible>
            </div>
          </div>
          <Timeline selectedTopic={selectedTopic} setSelectedTopic={setSelectedTopic} />
        </div>
      </Container>
    </div>
  );
};

export default withPublicAccess(HomePage);
