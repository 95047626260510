import dayjs, { Dayjs } from "dayjs";
import { Accordion } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import { FaHistory } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Collapse from "../../components/Collapse";
import { withAuth } from "../../contexts/auth.context";
import { useStatsActMonth } from "../../services/stats.services";
import AppPageLayout from "../DocumentPage/app-page-layout";
import StatsActsEditorCareChart from "./stats-acts-editor-care-chart";
import StatsActsSccChart from "./stats-acts-scc-chart";
import StatsActsTable from "./stats-acts-table";
import "./stats-page.scss";

interface StatsPageProps {
  year: string;
  month: string;
}

const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const StatsActsMonthPage = () => {
  const params = useParams();
  const year = params.year as string;
  const month = params.month as string;
  const currentYear = new Date().getFullYear();

  return (
    <AppPageLayout
      title={"Статистика: Актове - месечна"}
      className="stats-page"
      copyrighted
      navigation={
        <Container
          fluid
          className="filters-sidebar"
          style={{
            boxShadow: "2px 4px 8px var(--search-page-landing-page-container-border-shadow)",
          }}
        >
          <Collapse icon={<FaHistory />} label="Години">
            <Accordion defaultActiveKey={year} flush>
              {range(currentYear, currentYear - 5, -1).map((filterYear) => (
                <Accordion.Item eventKey={filterYear.toString()} key={filterYear}>
                  <Accordion.Header>{filterYear}</Accordion.Header>
                  <Accordion.Body>
                    <ListGroup
                      variant="flush"
                      style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
                    >
                      {range(
                        filterYear === currentYear ? new Date().getMonth() + 1 : 12,
                        1,
                        -1
                      ).map((filterMonth) => (
                        <ListGroupItem
                          key={filterMonth}
                          style={{
                            borderLeft:
                              "3px solid " +
                              (year.toLowerCase() === filterYear.toString() &&
                              month.toLowerCase() === filterMonth.toString()
                                ? "var(--history-page-active-filter)"
                                : "transparent"),
                          }}
                        >
                          <Link to={`/stats/acts/${filterYear}/${filterMonth}`}>
                            {dayjs(`${filterYear}/${filterMonth}`, "YYYY/M").format("MMMM")}
                          </Link>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            {/* <ListGroup
              variant="flush"
              style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
            >
              {range(currentYear, currentYear - 3, -1).map((filterYear) => (
                <>
                  <ListGroupItem key={filterYear} className="fw-bold">
                    {filterYear}
                  </ListGroupItem>
                  {range(filterYear === currentYear ? new Date().getMonth() + 1 : 12, 1, -1).map(
                    (filterMonth) => (
                      <ListGroupItem
                        key={filterMonth}
                        style={{
                          paddingLeft: "20px",
                          borderLeft:
                            "3px solid " +
                            (year.toLowerCase() === filterYear.toString() &&
                            month.toLowerCase() === filterMonth.toString()
                              ? "var(--history-page-active-filter)"
                              : "transparent"),
                        }}
                      >
                        <Link to={`/stats/acts/${filterYear}/${filterMonth}`}>
                          {dayjs(`${filterYear}/${filterMonth}`, "YYYY/M").format("MMMM")}
                        </Link>
                      </ListGroupItem>
                    )
                  )}
                </>
              ))}
            </ListGroup> */}
          </Collapse>
        </Container>
      }
    >
      <Container fluid className="m-0">
        <StatsPageComponent year={year} month={month} />
      </Container>
    </AppPageLayout>
  );
};

const StatsPageComponent: React.FC<StatsPageProps> = ({ year, month }) => {
  const intl = useIntl();
  const monthEntries = useStatsActMonth(year, month);
  const monthEntriesNoGaps = monthEntries
    ? [
        ...dayjsRange(
          dayjs(`${year}/${month}/1`, "YYYY/M/D"),
          dayjs(`${year}/${parseInt(month) + 1}/1`, "YYYY/M/D"),
          [1, "day"],
          "YYYY/MM/DD"
        ),
        `${year}/${month}`,
      ].map((period) => {
        return (
          monthEntries.find((entry) => entry.period === period) || {
            period,
            type: period === `${year}/${month}` ? "month" : "day",
            role: period === `${year}/${month}` ? "total" : "item",
          }
        );
      })
    : undefined;

  return (
    <div style={{ marginBottom: "5px" }}>
      <Card
        style={{
          boxShadow: "2px 4px 8px var(--search-page-landing-page-container-border-shadow)",
        }}
      >
        <Container className="ps-0">
          <h4 className="mt-3 mb-5 me-4">
            {dayjs(`${year}/${month}`, "YYYY/M").format("MMMM YYYY")}
          </h4>
          <StatsActsSccChart
            monthStats={monthEntriesNoGaps}
            xAxisLabel={intl.formatMessage({ id: "stats.label.date" })}
          />
          <StatsActsEditorCareChart
            monthStats={monthEntriesNoGaps}
            xAxisLabel={intl.formatMessage({ id: "stats.label.date" })}
          />
        </Container>
        <Container>
          <StatsActsTable
            monthStats={monthEntriesNoGaps}
            periodLabel={intl.formatMessage({ id: "stats.label.date" })}
          />
        </Container>
      </Card>
    </div>
  );
};

export function dayjsRange(from: Dayjs, to: Dayjs, step: [number, string], format: string) {
  const list = [];
  let current = from;
  while (current.isBefore(to)) {
    list.push(current.format(format));
    current = current.add(...step);
  }
  return list;
}

export default withAuth(StatsActsMonthPage);
