import { EntityModel } from "hateoas-hal-types";
import { FunctionComponent } from "react";
import { hasPrivilege, Privileges, useAuth } from "../../contexts/auth.context";
import { NewsArticle } from "../../models/NewsArticle.model";
import { updateNewsArticleStatus } from "../../services/news-article-services";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";
import DocumentCollectionActions from "../DocumentPage/document-collection-actions";
import DocumentEditAction from "../DocumentPage/document-edit-action";
import DocumentStatusAction from "../DocumentPage/document-status-action";

interface NewsArticlePageActionsProps {
  article: EntityModel<NewsArticle>;
}

const NewsArticlePageActions: FunctionComponent<NewsArticlePageActionsProps> = ({ article }) => {
  const { currentUser } = useAuth();
  const hasEditPrivilege = hasPrivilege(Privileges.EDIT_ARTICLES)(currentUser);
  return (
    <DocumentActionsContainer document={article}>
      {hasEditPrivilege && <DocumentEditAction />}
      <DocumentStatusAction
        document={article}
        setStatus={(status) => updateNewsArticleStatus(article.id, status)}
      />
      <DocumentCollectionActions accessMode={article.accessMode} />
      <DocumentCloseAction />
    </DocumentActionsContainer>
  );
};

export default NewsArticlePageActions;
