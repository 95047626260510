import dayjs from "dayjs";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Nav, OverlayTrigger, Tab, Table, Tooltip } from "react-bootstrap";
import {
  FaAngleLeft,
  FaAngleRight,
  FaRegClone,
  FaRegThumbsUp,
  FaSync,
  FaThumbsUp,
} from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { mutate } from "../../api";
import { InlineLinkButton } from "../../components/Button/button";
import LabelValue from "../../components/label-value";
import { InlineLink } from "../../components/Link/link";
import LoadingIndicator from "../../components/LoadingIndicator/loadingIndicator";
import SimpleSelect from "../../components/SimpleSelect";
import Skeleton from "../../components/Skeleton";
import { all, hasPrivilege, hasUsername, Privileges, Visible } from "../../contexts/auth.context";
import { useSelectedActContext } from "../../contexts/selected-act.context";
import { Chat, ChatDocuments, ChatResponseProgress, isOnError } from "../../models/Chat.model";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { JudgementAct } from "../../models/JudgementAct.model";
import SummaryQa from "../../models/SummaryQa.model";
import {
  regenerateResponse,
  useChat,
  useChatDocuments,
  useResponseContents,
} from "../../services/chat-services";
import { addUserReaction, useUserReactions } from "../../services/document-services";
import { judgementActHref } from "../../services/judgement-act-services";
import { useChatModels } from "../../services/ref-data-services";
import { summaryHref } from "../../services/summary-services";
import ResultsTableLoading from "../AdminPage/ResultsTable/results-table-loadng";
import DocumentPageLayout from "../DocumentPage/document-page-layout";
import ResponsiveFilter from "../SearchPage/FiltersSidebar/responsive-filter";
import "../SearchPage/SearchResults/search-results.scss";
import ChatViewPageActions from "./chat-view-page-actions";
import ChatViewPageNavgation from "./chat-view-page-navigation";
import "./chat-view-page.scss";
import SearchInputLexebraGPT from "./gpt-input";
import SampleSearchCards from "./sample-search-cards";
import SubmitNegativeFeedback from "./submit-feedback";

export type ChatViewPageMode = "view" | "edit" | "landing";

const ChatViewPage = () => {
  const { chatId } = useParams();
  const chat = useChat(chatId);
  const navigate = useNavigate();

  const [mode, setMode] = useState<ChatViewPageMode>(chatId ? "view" : "landing");

  const [submitting, setSubmitting] = useState(false);

  const anyResponseInProgress =
    chat?.responses.findIndex((r) => r.progress === ChatResponseProgress.GENERATING) !== -1;

  useEffect(() => {
    if (anyResponseInProgress) {
      const interval = setInterval(() => {
        mutate(`/chats/${chat?.id}`, (current: Chat) => current, true);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [anyResponseInProgress, chat?.id]);

  useEffect(() => {
    setMode(chatId ? "view" : "landing");
  }, [chatId]);

  if (chatId && !chat) {
    return (
      <DocumentPageLayout
        mode={mode}
        className="chat-page"
        menu={chatId ? <ChatViewPageActions chat={chat} setMode={setMode} mode={mode} /> : null}
        navigation={<ChatViewPageNavgation />}
      >
        <Card className="mb-3 highlight-card">
          <Card.Body className="d-flex flex-column gap-2">
            <Skeleton count={10} />
          </Card.Body>
        </Card>
        <Tab.Container defaultActiveKey={"0"}>
          <Nav variant="pills" className="opinion-tabs">
            <Nav.Item>
              <Nav.Link eventKey="0">
                <FormattedMessage id="view-summary.labels.relatedJudgementActs.questions" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="1">
                <FormattedMessage id="view-summary.labels.relatedJudgementActs.acts" />
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="0">
              <ResultsTableLoading perRowCount={4} count={5} showHeader={false} />
            </Tab.Pane>
            <Tab.Pane eventKey="1">
              <ResultsTableLoading perRowCount={4} count={5} showHeader={false} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </DocumentPageLayout>
    );
  }

  return (
    <DocumentPageLayout
      id={chat?.id}
      title={chat?.title}
      defaultTitle="Попитай Лексебра"
      mode={mode}
      className="chat-page"
      copyrighted
      accessMode={chat?.accessMode || DocumentAccessMode.PAID}
      status={chat?.status}
      menu={chatId ? <ChatViewPageActions chat={chat} setMode={setMode} mode={mode} /> : null}
      navigation={<ChatViewPageNavgation />}
    >
      {(!chatId || mode === "edit") && (
        <>
          <SearchInputLexebraGPT
            chatId={chatId}
            query={chat?.query}
            mode={mode}
            setMode={setMode}
            submitting={submitting}
            setSubmitting={setSubmitting}
            onCreate={(newChat) => navigate(`${newChat.id}`)}
          />
        </>
      )}
      {!chatId && !submitting && <SampleSearchCards />}
      {chat && !submitting && <ChatViewPageForm chat={chat} mode={mode} />}
    </DocumentPageLayout>
  );
};

export const ChatViewPageForm: React.FC<{
  chat: Chat;
  mode: ChatViewPageMode;
  quickView?: boolean;
}> = ({ chat, mode, quickView = false }) => {
  const { setSelectedActId } = useSelectedActContext();

  const navigate = useNavigate();
  const { hash } = useLocation();

  const currentResponseIdx = hash ? chat?.responses.findIndex((r) => r.id === hash?.slice(1)) : 0;
  const currentResponse = chat?.responses[currentResponseIdx];

  const { data: chatModels } = useChatModels();

  const [submitting, setSubmitting] = useState(false);

  const { reactions } = useUserReactions(currentResponse?.id);
  const reaction = reactions.length ? reactions[0] : undefined;

  const chatDocuments = useChatDocuments(
    chat?.status === DocumentStatus.GENERATED ? undefined : chat?.id
  );

  return (
    <>
      <Card className={`mb-3 highlight-card`}>
        <Card.Body style={{ flexDirection: "column" }}>
          {mode === "view" && (
            <p>
              <Card.Text className="fw-bold">Въпрос: </Card.Text>
              <Card.Text className="question-text">{chat.query}</Card.Text>
            </p>
          )}
          <Card.Text className="fw-bold">Отговор: </Card.Text>
          {currentResponse?.progress === ChatResponseProgress.GENERATING ||
          currentResponse?.progress === ChatResponseProgress.RECEIVING ? (
            <>
              <LoadingIndicator response={currentResponse} />
              {currentResponse?.progress === ChatResponseProgress.RECEIVING && (
                <PartialResponseContents
                  chatId={chat.id}
                  responseId={currentResponse.id}
                  chatDocuments={chatDocuments}
                />
              )}
            </>
          ) : isOnError(currentResponse?.progress) ? (
            <Card.Text className="text-danger">{currentResponse.content}</Card.Text>
          ) : (
            <>
              <div className="response-text">
                {currentResponse.content &&
                  parse(currentResponse.content, {
                    replace: (node) => {
                      if ((node as any).data) {
                        return RenderChatResponseWithReferences(
                          (node as any).data,
                          chatDocuments?.acts || [],
                          chatDocuments?.questions || [],
                          setSelectedActId
                        );
                      }
                    },
                  })}
              </div>
              {currentResponse && reaction && (
                <>
                  <Card.Text className="fw-bold">
                    Оценка:{" "}
                    <Badge bg={reaction.type === "LIKE" ? "success" : "danger"}>
                      {reaction.type === "LIKE" ? "Добър отговор" : "Лош отговор"}
                    </Badge>
                  </Card.Text>
                  <Card.Text>
                    <pre>{reaction?.comment}</pre>
                  </Card.Text>
                </>
              )}
            </>
          )}
          {/* {!!currentResponse && chat?.user.email !== currentUser?.email && (
            <span
              className="text-muted text-end"
              style={{ fontSize: "0.8rem", fontStyle: "italic" }}
            >
              Генерирано от {chat?.user.firstName} {chat?.user.lastName} на{" "}
              {intl.formatDate(dayjs(chat.createdOn, "YYYY-MM-DD").toDate(), {
                month: "long",
                day: "2-digit",
                year: "numeric",
              })}
            </span>
          )} */}
          {currentResponse?.progress !== ChatResponseProgress.GENERATING && mode === "view" && (
            <div
              className={`d-flex gap-2 align-items-center response-action-buttons ${
                submitting ? "submitting" : ""
              }`}
            >
              {chat.responses.length > 1 && !quickView && (
                <div className="d-flex gap-1 align-items-center">
                  <OverlayTrigger
                    placement="top"
                    overlay={(props) => (
                      <Tooltip id="prev-answer" {...props}>
                        Предишен отговор
                      </Tooltip>
                    )}
                  >
                    <span className="copy-button ms-0">
                      <InlineLinkButton
                        disabled={currentResponseIdx === 0}
                        onClick={() => {
                          navigate(`#${chat.responses[currentResponseIdx - 1].id}`);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <FaAngleLeft />
                      </InlineLinkButton>
                    </span>
                  </OverlayTrigger>
                  <span>
                    {currentResponseIdx + 1}/{chat.responses.length}
                  </span>

                  <OverlayTrigger
                    placement="top"
                    overlay={(props) => (
                      <Tooltip id="next-answer" {...props}>
                        Следващ отговор
                      </Tooltip>
                    )}
                  >
                    <span className="copy-button ms-0">
                      <InlineLinkButton
                        disabled={currentResponseIdx >= chat.responses.length - 1}
                        onClick={() => {
                          navigate(`#${chat.responses[currentResponseIdx + 1].id}`);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <FaAngleRight />
                      </InlineLinkButton>
                    </span>
                  </OverlayTrigger>
                </div>
              )}
              {currentResponse?.progress === ChatResponseProgress.COMPLETED && (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={(props) => (
                      <Tooltip id="copy-answer" {...props}>
                        Копирай отговора
                      </Tooltip>
                    )}
                  >
                    <span className="copy-button">
                      <InlineLinkButton
                        onClick={async () => {
                          await navigator.clipboard.writeText(currentResponse?.content || "");
                          toast.success("Отговорът беше копиран успешно.");
                        }}
                      >
                        <FaRegClone />
                      </InlineLinkButton>
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={(props) => (
                      <Tooltip id="good-answer" {...props}>
                        Добър отговор
                      </Tooltip>
                    )}
                  >
                    <span className="copy-button ms-0">
                      {reaction?.type !== "LIKE" ? (
                        <InlineLinkButton
                          onClick={async () => {
                            try {
                              setSubmitting(true);
                              await addUserReaction(currentResponse.id, {
                                type: "LIKE",
                              });
                              toast.success("Благодарим Ви за обратната връзка.");
                            } catch (e) {
                              console.log(e);
                              toast.error("Възникна грешка при изпращане на обратната връзка.");
                            } finally {
                              setSubmitting(false);
                            }
                          }}
                        >
                          <FaRegThumbsUp />
                        </InlineLinkButton>
                      ) : (
                        <span>
                          <FaThumbsUp />
                        </span>
                      )}
                    </span>
                  </OverlayTrigger>
                  <SubmitNegativeFeedback
                    responseId={currentResponse.id}
                    feedbackScore={reaction?.type}
                  />
                  <Visible
                    when={all(
                      hasPrivilege(Privileges.EDIT_SUMMARIES),
                      hasUsername(chat?.user.email)
                    )}
                  >
                    <OverlayTrigger
                      placement="top"
                      overlay={(props) => (
                        <Tooltip id="regenrate-answer" {...props}>
                          Регенерирай отговора
                        </Tooltip>
                      )}
                    >
                      <span className="copy-button ms-0 align-self-end">
                        <ResponsiveFilter>
                          <SimpleSelect
                            className="inline-select"
                            options={chatModels}
                            getOption={(model) => ({
                              label: model.shortName,
                              value: model.id,
                            })}
                            value={[currentResponse.model]}
                            onChange={(value) => {
                              const existingResponse = chat?.responses.find(
                                (r) => r.model.id === value[0].id
                              );
                              if (existingResponse) {
                                navigate(`#${existingResponse.id}`);
                                return;
                              }
                              setSubmitting(true);
                              regenerateResponse(chat.id, value[0])
                                .then((result) => {
                                  navigate(`#${result.responses[result.responses.length - 1].id}`);
                                })
                                .catch(() => {
                                  toast.error("Възникна грешка при регенериране на отговора.");
                                })
                                .finally(() => {
                                  setSubmitting(false);
                                });
                            }}
                            isMulti={false}
                            valueRenderer={(value) => (
                              <span className="d-flex align-items-end gap-1">
                                <FaSync />
                                <span className="value-label">
                                  {value.map((s) => s.label).join(", ")}
                                </span>
                              </span>
                            )}
                          />
                        </ResponsiveFilter>
                      </span>
                    </OverlayTrigger>
                  </Visible>
                </>
              )}
            </div>
          )}
        </Card.Body>
      </Card>

      <ChatDocumentsContainer chatDocuments={chatDocuments} />
    </>
  );
};

const PartialResponseContents: React.FC<{
  chatId: string;
  responseId: string;
  chatDocuments?: ChatDocuments;
}> = ({ chatId, responseId, chatDocuments }) => {
  const { data: contents } = useResponseContents(chatId, responseId);
  const { setSelectedActId } = useSelectedActContext();

  useEffect(() => {
    if (contents?.status === "ok") {
      toast.success("Отговорът беше генериран успешно.");
      mutate(`/chats/recent`);
      mutate(`/chats/${chatId}`, (current: Chat) => current, true);
    } else if (contents?.status === "error") {
      toast.error("Възникна грешка при генерирането на отговора.");
      mutate(`/chats/recent`);
      mutate(`/chats/${chatId}`, (current: Chat) => current, true);
    }
  }, [contents?.status, chatId]);
  return (
    <div className="mt-2 response-text">
      {contents?.data &&
        parse(contents.data, {
          replace: (node) => {
            if ((node as any).data) {
              return RenderChatResponseWithReferences(
                (node as any).data,
                chatDocuments?.acts || [],
                chatDocuments?.questions || [],
                setSelectedActId
              );
            }
          },
        })}
    </div>
  );
};

const ChatDocumentsContainer: React.FC<{ chatDocuments?: ChatDocuments }> = ({ chatDocuments }) => {
  const { setSelectedActId } = useSelectedActContext();
  const { formatMessage } = useIntl();

  return (
    <Tab.Container defaultActiveKey={"0"}>
      <Nav variant="pills" className="opinion-tabs">
        <Nav.Item>
          <Nav.Link eventKey="0">
            <FormattedMessage id="view-summary.labels.relatedJudgementActs.questions" />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="1">
            <FormattedMessage id="view-summary.labels.relatedJudgementActs.acts" />
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <Tab.Content>
        <Tab.Pane eventKey="0">
          <div className="results-table-container">
            {chatDocuments ? (
              <Table borderless responsive size="sm" className="results-table">
                <tbody>
                  {chatDocuments.questions.map((item) => (
                    <tr key={item.relatedQuestion.id}>
                      <td className="main-content">
                        <div className="row-content">
                          <div className="row-main-info">
                            <InlineLink
                              to={summaryHref(
                                `${item.relatedQuestion.summary.id}#${item.relatedQuestion.id}`
                              )}
                              onClick={(e) => {
                                if (!e.ctrlKey && !e.metaKey) {
                                  e.preventDefault();
                                  setSelectedActId("summary:" + item.relatedQuestion.summary.id);
                                }
                              }}
                            >
                              {item.relatedQuestion.question}
                            </InlineLink>
                            <span className="text-muted ms-1">({item.score.toFixed(2)})</span>
                            <div>{item.relatedQuestion.shortTitle}</div>
                            {item.relatedQuestion.caseType &&
                              item.relatedQuestion.caseType.code !== "CRIMINAL" &&
                              item.relatedQuestion.caseType.code !== "CRIMINAL_PRIVATE" && (
                                <LabelValue
                                  label={formatMessage({
                                    id: "view-judgement.labels.proceedingBy",
                                  })}
                                >
                                  {item.relatedQuestion.proceeding?.shortName}
                                </LabelValue>
                              )}
                            {item.relatedQuestion.actDate &&
                              /\d{4}\/\d{2}\/\d{2}/.test(item.relatedQuestion.actDate) && (
                                <LabelValue
                                  label={formatMessage({ id: "view-judgement.labels.actDate" })}
                                >
                                  {dayjs(item.relatedQuestion.actDate, "YYYY/MM/DD").format(
                                    "DD.MM.YYYY"
                                  )}{" "}
                                  г.
                                </LabelValue>
                              )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <ResultsTableLoading perRowCount={4} count={5} showHeader={false} />
            )}
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="1">
          <div className="results-table-container">
            {chatDocuments ? (
              <Table borderless responsive size="sm" className="results-table">
                <tbody>
                  {chatDocuments.acts.map((item) => (
                    <tr key={item.relatedJudgementAct.id}>
                      <td className="main-content">
                        <div className="row-content">
                          <div className="row-main-info">
                            <InlineLink
                              to={judgementActHref(item.relatedJudgementAct.id)}
                              onClick={(e) => {
                                if (!e.ctrlKey && !e.metaKey) {
                                  e.preventDefault();
                                  setSelectedActId(item.relatedJudgementAct.id);
                                }
                              }}
                            >
                              {item.relatedJudgementAct.title}
                            </InlineLink>
                            <span className="text-muted ms-1">({item.score.toFixed(2)})</span>
                            {item.relatedJudgementAct.keywords && (
                              <div>{item.relatedJudgementAct.keywords}</div>
                            )}

                            {item.relatedJudgementAct.caseType &&
                              item.relatedJudgementAct.caseType.code !== "CRIMINAL" &&
                              item.relatedJudgementAct.caseType.code !== "CRIMINAL_PRIVATE" && (
                                <LabelValue
                                  label={formatMessage({
                                    id: "view-judgement.labels.proceedingBy",
                                  })}
                                >
                                  {item.relatedJudgementAct.proceeding?.shortName}
                                </LabelValue>
                              )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <ResultsTableLoading perRowCount={4} count={5} showHeader={false} />
            )}
          </div>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

const RenderChatResponseWithReferences = (
  text: string,
  acts: { score: number; relatedJudgementAct: JudgementAct }[],
  questions: { score: number; relatedQuestion: SummaryQa }[],
  setSelectedActId: (id: string) => void
) => {
  const references = [
    ...acts.map((actItem) => ({
      id: actItem.relatedJudgementAct.id,
      title: actItem.relatedJudgementAct.title,
      keywords: actItem.relatedJudgementAct.keywords,
    })),
    ...questions.map((questionItem) => ({
      id: questionItem.relatedQuestion.summary.relatedJudgementAct.id,
      title: questionItem.relatedQuestion.summary.title,
      keywords: questionItem.relatedQuestion.summary.relatedJudgementAct.keywords,
    })),
  ];

  let result: (string | JSX.Element)[] = [text];

  references.forEach((referenceItem) => {
    const reference = referenceItem.title;

    if (!reference) return;

    const newResult: (string | JSX.Element)[] = [];
    result.forEach((token) => {
      if (typeof token !== "string") {
        newResult.push(token);
      } else {
        let remaining = token;
        while (remaining.indexOf(reference) > -1) {
          const idx = remaining.indexOf(reference);
          newResult.push(remaining.substring(0, idx));
          newResult.push(
            <>
              <Button
                variant="link"
                size="sm"
                key={`${referenceItem.id}-${idx}`}
                className="inline-link"
                style={{ display: "contents" }}
                onClick={() => setSelectedActId(referenceItem.id)}
              >
                {reference}
              </Button>
              {referenceItem.keywords ? ` [${referenceItem.keywords}]` : null}
            </>
          );
          remaining = remaining.substring(idx + reference.length);
        }
        newResult.push(remaining);
      }
    });
    result = newResult;
  });

  const finalResult: (string | JSX.Element)[] = [];
  result.forEach((token) => {
    if (typeof token === "string") {
      const regex = /\*\*(.*?)\*\*/g;
      const parts = token.split(regex);
      parts.forEach((part, index) => {
        if (index % 2 === 1) {
          finalResult.push(<strong key={`bold-${index}`}>{part}</strong>);
        } else {
          finalResult.push(part);
        }
      });
    } else {
      finalResult.push(token);
    }
  });

  return <>{finalResult}</>;
};

export default ChatViewPage;
