import { EntityModel } from "hateoas-hal-types";
import { Cache } from "swr";
import useRequest, {
  del,
  get,
  mutate,
  post,
  prefetch,
  prefetchSearchRequest,
  put,
  useSearchRequest,
} from "../api";
import DocumentStatus from "../models/DocumentStatus.enum";
import { Summary } from "../models/Summary.model";
import SummaryQa from "../models/SummaryQa.model";

const SUMMARIES_BASE_URL = "/summaries";

export const summaryHref = (id: string): string => `${SUMMARIES_BASE_URL}/${id}`;

export const useSummaries = () => useSearchRequest<Summary>("/summaries");

export const useQuestions = () => useSearchRequest<SummaryQa>("/questions");

// export const fetchAnswer = (
//   query: string,
//   questionIds: string[],
//   chunkHandler: (chunk: string | null) => void
// ) => {
//   const questionIdsParam = questionIds
//     .map((id) => `questionIds=${encodeURIComponent(id)}`)
//     .join("&");
//   const url = `/api/questions/answer?query=${encodeURIComponent(query)}&${questionIdsParam}`;

//   fetch(url)
//     .then((response) => {
//       if (!response.body) {
//         throw new Error("ReadableStream not supported in this browser.");
//       }

//       const reader = response.body.getReader();
//       const decoder = new TextDecoder();

//       function read() {
//         reader
//           .read()
//           .then(({ done, value }) => {
//             if (done) {
//               chunkHandler(null);
//               return;
//             }
//             const chunk = decoder.decode(value, { stream: true });
//             chunkHandler(chunk);
//             read();
//           })
//           .catch((error) => {
//             console.error("Error reading stream:", error);
//             chunkHandler(null);
//           });
//       }
//       read();
//     })
//     .catch((error) => {
//       console.error("Error fetching answer:", error);
//       chunkHandler(null);
//     });
// };

export const prefetchSummaries = (searchParams: URLSearchParams, cache: Cache<any>) =>
  prefetchSearchRequest(SUMMARIES_BASE_URL, searchParams, cache);

export const prefetchQuestions = (searchParams: URLSearchParams, cache: Cache<any>) =>
  prefetchSearchRequest("/questions", searchParams, cache);

export const useSummary = (id?: string) => {
  const { data } = useRequest<EntityModel<Summary>>(id ? `${SUMMARIES_BASE_URL}/${id}` : null);
  return data;
};

export const fetchSummary = (id: string) => {
  return get<EntityModel<Summary>>(`${SUMMARIES_BASE_URL}/${id}`);
};

export const prefetchSummary = (id: string, cache: Cache<any>) => {
  return prefetch(`${SUMMARIES_BASE_URL}/${id}`, cache);
};

export const fetchSummaryPdf = (id: string) => {
  return get<{ data: string }>(`${SUMMARIES_BASE_URL}/${id}/pdf`);
};

export const fetchSummaryRtf = (id: string) => {
  return get<{ data: string }>(`${SUMMARIES_BASE_URL}/${id}/rtf`);
};

export const saveSummary = async (data: Summary, summaryId?: string) => {
  const result = (
    summaryId
      ? await put(`${SUMMARIES_BASE_URL}/${summaryId}`, data)
      : await post(SUMMARIES_BASE_URL, data)
  ) as EntityModel<Summary>;
  mutate(`${SUMMARIES_BASE_URL}/${summaryId}`, () => result, false);
  return result;
};

export const updateSummaryStatus = async (summaryId: string, data: DocumentStatus) => {
  const result = (await put(
    `${SUMMARIES_BASE_URL}/${summaryId}/status`,
    data,
    true
  )) as EntityModel<Summary>;
  await mutate(
    `${SUMMARIES_BASE_URL}/${summaryId}`,
    (current: Summary) => {
      const updated = { ...current };
      updated.status = data;
      return updated;
    },
    false
  );
  return result;
};

export const addTopicToSummary = async (summaryId: string, topicId: string | number) => {
  await post(`${SUMMARIES_BASE_URL}/${summaryId}/topics/${topicId}`, {}, true);
};

export const removeTopicFromSummary = async (summaryId: string, topicId: string | number) => {
  await del(`${SUMMARIES_BASE_URL}/${summaryId}/topics/${topicId}`, {}, true);
};
