import dayjs from "dayjs";
import { BiTimeFive } from "react-icons/bi";
import { FormattedDate, FormattedMessage } from "react-intl";
import PreloadingLink from "../../components/PreloadingLink";
import Skeleton from "../../components/Skeleton";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import { prefetchDocumentStats } from "../../services/document-services";
import { useFocusedActs } from "../../services/home-services";
import {
  judgementActHref,
  prefetchJudgementAct,
  prefetchJudgementActs,
} from "../../services/judgement-act-services";
import AccessIndicator from "./access-indicator";
import { PUBLISH_DATE_FORMAT } from "./focused-section";

const FocusedJudgementActs = () => {
  const { data } = useFocusedActs();
  return data ? (
    <>
      {data.length! > 0 ? (
        data.map((act) => (
          <div key={act.id}>
            <div className="item-heading">
              <PreloadingLink
                preload={(cache) => prefetchJudgementAct(act.id, cache)}
                to={judgementActHref(act.id)}
                state={{ from: "/" }}
                protect={act.accessMode === DocumentAccessMode.PAID}
              >
                {act.title}
              </PreloadingLink>
              <AccessIndicator mode={act.accessMode} />
            </div>
            {act.keywords ? (
              <div>{act.keywords}</div>
            ) : act.reporters?.length > 0 ? (
              <div>
                <span className="label">Докладчик</span>
                {act.reporters?.join(", ")}
              </div>
            ) : null}
            <div className="item-details">
              <FormattedDate
                value={dayjs(act.actDate, PUBLISH_DATE_FORMAT).toDate()}
                month="long"
                day="2-digit"
              />
              {act.timeReading ? (
                <>
                  &nbsp;-&nbsp;
                  <BiTimeFive />
                  &nbsp;{act.timeReading} мин. за преглед
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="missing-articles">
          <FormattedMessage id="home-page.sections.focused-judgement-acts.no-data" />
        </div>
      )}
      <PreloadingLink
        preload={async (cache) => {
          await prefetchDocumentStats(new URLSearchParams("status=FOCUSED"), cache);
          await prefetchJudgementActs(new URLSearchParams("status=FOCUSED"), cache);
        }}
        to={`/search/acts?q=9ba49a229911cd8ad9af3ccc24a08ca3`}
        event="onMouseEnter"
        className="view-all-link"
      >
        <FormattedMessage id="home-page.sections.view-all.link" />
      </PreloadingLink>
    </>
  ) : (
    <Skeleton count={5} />
  );
};

export default FocusedJudgementActs;
