import { useHotkeys } from "react-hotkeys-hook";
import { FaEdit } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useProtectedAction } from "../../contexts/auth.context";
import DocumentAction from "./document-action";

const DocumentEditAction: React.FC<{ handler?: () => void }> = ({ handler }) => {
  const navigate = useNavigate();
  const handleAction = handler || (() => navigate("edit"));
  const protectedAction = useProtectedAction();
  const edit = protectedAction(handleAction);
  const intl = useIntl();

  useHotkeys("ctrl+e,command+e", (e) => {
    e.preventDefault();
    edit();
  });

  return (
    <DocumentAction
      handler={edit}
      icon={<FaEdit />}
      label={intl.formatMessage({ id: "document-actions.edit" })}
    />
  );
};

export default DocumentEditAction;
