import { saveAs } from "file-saver";
import { EntityModel } from "hateoas-hal-types";
import { FunctionComponent } from "react";
import { FaDownload, FaRegClone } from "react-icons/fa";
import { useNavigate } from "react-router";
import {
  all,
  hasName,
  hasPrivilege,
  oneOf,
  Privileges,
  Visible,
} from "../../contexts/auth.context";
import { useLoadingProgress } from "../../contexts/loading-progress.context";
import { isPublishedStatus } from "../../models/DocumentStatus.enum";
import { EditorCollection } from "../../models/EditorCollection.model";
import {
  fetchEditorCollectionActsExcel,
  fetchEditorCollectionWord,
} from "../../services/editor-collection-services";
import DocumentAction from "../DocumentPage/document-action";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";
import DocumentEditAction from "../DocumentPage/document-edit-action";
import EditorCollectionSearchAction from "./editor-collection-search-action";
import ExportCollection from "./export-collection";

interface EditorCollectionPageActionsProps {
  collection: EntityModel<EditorCollection>;
}

const EditorCollectionPageActions: FunctionComponent<EditorCollectionPageActionsProps> = ({
  collection,
}) => {
  const navigate = useNavigate();
  const progress = useLoadingProgress();
  return (
    <DocumentActionsContainer document={collection}>
      <EditorCollectionSearchAction />
      <Visible
        when={oneOf(
          all(
            hasName(collection.editor?.name),
            () => !isPublishedStatus(collection.status),
            hasPrivilege(Privileges.EDIT_COLLECTIONS)
          ),
          hasPrivilege(Privileges.EDIT_ALL_EDITOR_COLLECTIONS)
        )}
      >
        <DocumentEditAction />
      </Visible>
      <Visible when={hasPrivilege(Privileges.EDIT_ALL_EDITOR_COLLECTIONS)}>
        <DocumentAction
          label={"Клонирай"}
          icon={<FaRegClone />}
          handler={() => {
            navigate(`/collections/new`, { state: { sourceId: collection.id } });
          }}
        />
        <DocumentAction
          handler={async () => {
            progress.start();
            try {
              const contents = (await fetchEditorCollectionActsExcel(collection.id)).data;
              saveAs(
                "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
                  contents,
                `${collection.title}.xlsx`
              );
            } finally {
              progress.complete();
            }
          }}
          icon={<FaDownload />}
          label="Експорт на списъка на актовете в Excel"
        ></DocumentAction>
        <ExportCollection
          handleExport={async (options) => {
            progress.start();
            try {
              const contents = (await fetchEditorCollectionWord(collection.id, options)).data;
              saveAs(
                "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," +
                  contents,
                `${collection.title}.docx`
              );
            } finally {
              progress.complete();
            }
          }}
        />
      </Visible>
      <DocumentCloseAction handler={() => navigate("/collections")} />
    </DocumentActionsContainer>
  );
};

export default EditorCollectionPageActions;
