import { EntityModel } from "hateoas-hal-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Badge, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { ActionButton, PrimaryButton, SecondaryButton } from "../../components/Button/button";
import { Chat } from "../../models/Chat.model";
import { startChat, StartChatInput, updateChat } from "../../services/chat-services";
import { ChatViewPageMode } from "./chat-view-page";
import "./gpt-input.scss";

const SearchInputLexebraGPT: React.FC<{
  chatId?: string;
  query?: string;
  mode: ChatViewPageMode;
  setMode: (mode: ChatViewPageMode) => void;
  onCreate: (chat: EntityModel<Chat>) => void;
  submitting: boolean;
  setSubmitting: (mode: boolean) => void;
}> = ({ query, mode, setMode, chatId, onCreate, submitting, setSubmitting }) => {
  const [inputValue, setInputValue] = useState(query || "");
  const [error, setError] = useState("");

  const onSearch = useCallback(
    async (searchText: string) => {
      if (!searchText) {
        return;
      }
      setSubmitting(true);
      const handler = chatId ? (data: StartChatInput) => updateChat(chatId, data) : startChat;
      handler({ query: searchText })
        .then((chat) => {
          setError("");
          chatId ? setMode("view") : onCreate(chat);
        })
        .catch((e) => {
          console.log(e);
          if (e.payload?.code === "LEX-001") {
            setError(e.payload.message);
          } else {
            toast.error("Възникна грешка при генерирането на отговора. Моля, опитайте отново.");
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [chatId, setSubmitting, setMode, onCreate]
  );

  // useEffect(() => {
  //   setInputValue(chat?.title || "");
  // }, [chat?.title]);

  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
      autoResizeTextarea();
    }
  }, []);

  const autoResizeTextarea = () => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  const charLimit = 800;
  const getCharCounterClass = () => {
    if (inputValue.length >= charLimit) {
      return "danger";
    } else if (inputValue.length >= charLimit * 0.8) {
      return "warning";
    } else {
      return "primary";
    }
  };

  return (
    <>
      <div className="textarea-button-container">
        <div className="d-flex w-100" style={{ opacity: 0.7 }}>
          <textarea
            className="form-control"
            placeholder="Моля въведете Вашият правен въпрос тук, напр. 'От кога тече давността при неплащане на банков кредит?'"
            ref={ref}
            value={inputValue}
            rows={2}
            onChange={(e) => {
              setInputValue(e.target.value?.substring(0, charLimit) || "");
              autoResizeTextarea();
            }}
            maxLength={charLimit}
            disabled={submitting}
            style={{ resize: "none", overflow: "hidden" }}
          />
          {!!inputValue.length && (
            <Badge
              bg={getCharCounterClass()}
              style={{ height: "fit-content" }}
              className="me-2 mt-2"
            >
              {inputValue.length}/{charLimit}
            </Badge>
          )}
        </div>

        <div className="d-flex gap-1 pb-3 w-100 flex-grow-0">
          {mode !== "edit" ? (
            <ActionButton
              messageId=""
              disabled={inputValue.trim() === "" || submitting || inputValue.length > charLimit}
              onClick={() => onSearch(inputValue)}
              submitting={submitting}
            >
              Попитай Лексебра
            </ActionButton>
          ) : (
            <>
              <PrimaryButton
                messageId=""
                disabled={inputValue.trim() === "" || submitting || query === inputValue}
                onClick={() => onSearch(inputValue)}
                submitting={submitting}
              >
                Запази
              </PrimaryButton>
              <SecondaryButton
                onClick={() => setMode("view")}
                messageId={"button.cancel"}
                disabled={submitting}
              />
            </>
          )}
          <div className=" flex-grow-1"></div>
        </div>
      </div>
      <div className="search-results-container">
        {error && (
          <ChatViewPageContainer>
            <pre>{error}</pre>
          </ChatViewPageContainer>
        )}
      </div>
    </>
  );
};

const ChatViewPageContainer: React.FC = ({ children }) => {
  return (
    <>
      <Card className={`mb-3 search-card`}>
        <Card.Body style={{ flexDirection: "column" }}>
          <Card.Text className="fw-bold">Отговор: </Card.Text>
          <Card.Text>{children}</Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default SearchInputLexebraGPT;
