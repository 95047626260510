/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useDeviceSelectors } from "react-device-detect";
import { FaBookOpen, FaHome, FaLock, FaNewspaper, FaRegArrowAltCircleRight } from "react-icons/fa";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../components/Button/button";
import { env } from "../../constants";
import {
  hasPrivilege,
  isLogged,
  not,
  Privileges,
  ProtectedLink,
  useAuth,
  Visible,
} from "../../contexts/auth.context";
import DocumentsMenu from "./documents-menu";
import DocumentsMenuAnonymous from "./documents-menu-anonymous";
import "./header.css";
import SearchMenu from "./search-menu";
import StatsMenu from "./stats-menu";
import UserMenu from "./user-menu";

const NavLink = (props) => <Link {...props} />;

const ProtectedNavLink = (props) => <ProtectedLink {...props} />;

export const popperConfig = {
  modifiers: [
    {
      name: "offset",
      enabled: true,
      options: {
        offset: [0, 10],
      },
    },
  ],
};

const Header = ({ children }) => {
  const location = useLocation();
  const { setShowLoginModal } = useAuth();

  const menuBarRef = useRef(null);
  useEffect(() => {
    const listener = () => {
      const menuBarDiv = menuBarRef.current;
      if (menuBarDiv) {
        window.scrollY > 35
          ? menuBarDiv.classList.add("scrolled")
          : menuBarDiv.classList.remove("scrolled");
      }
    };
    document.addEventListener("scroll", listener);
    return () => document.removeEventListener("scroll", listener);
  }, []);

  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);

  const auth = useAuth();

  const accessToAskLexebra = auth.hasPrivilige(Privileges.EDIT_SUMMARIES);
  const tooltipShowStatus = false;
  return (
    <>
      {isMobileOnly && accessToAskLexebra && <HeaderMobile />}
      <Navbar
        className={`user-bar ${isMobileOnly ? "user-bar-mobile" : ""}`}
        fixed={isMobileOnly ? "bottom" : "top"}
        ref={menuBarRef}
      >
        <Navbar.Brand href="/">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 20" className="fullLogo">
            <path fill="#005180" d="M25.27,2.23h1.65V16.35h8.7v1.42H25.27Z" />
            <path fill="#005180" d="M49.34,16.35v1.42h-11V2.23H49V3.65H40V9.18h8v1.4H40v5.77Z" />
            <path
              fill="#005180"
              d="M62.74,17.77l-5-6.84-5,6.84H50.84l5.91-8L51.22,2.23h1.89l4.68,6.35,4.66-6.35h1.78l-5.51,7.5,5.93,8Z"
            />
            <path fill="#005180" d="M78,16.35v1.42H67V2.23H77.68V3.65h-9V9.18h8v1.4h-8v5.77Z" />
            <path
              fill="#005180"
              d="M94.86,13.62c0,2.64-2,4.15-5.84,4.15H81.88V2.23h6.7C92,2.23,94,3.7,94,6.23a3.61,3.61,0,0,1-2.38,3.53A3.7,3.7,0,0,1,94.86,13.62Zm-11.34-10V9.22h5c2.48,0,3.93-1,3.93-2.82S91,3.59,88.47,3.59Zm9.7,9.89c0-2-1.49-2.9-4.22-2.9H83.52v5.84H89C91.73,16.42,93.22,15.53,93.22,13.48Z"
            />
            <path
              fill="#005180"
              d="M109.22,17.77l-3.55-5a9.86,9.86,0,0,1-1.24.07h-4.18v4.93H98.61V2.23h5.82c3.95,0,6.35,2,6.35,5.33a4.81,4.81,0,0,1-3.56,4.88l3.8,5.33Zm-.09-10.21c0-2.49-1.64-3.91-4.75-3.91h-4.13v7.79h4.13C107.49,11.44,109.13,10,109.13,7.56Z"
            />
            <path
              fill="#005180"
              d="M124.4,13.62h-8.65l-1.87,4.15h-1.71l7.11-15.54h1.62L128,17.77h-1.73Zm-.6-1.33-3.72-8.35-3.73,8.35Z"
            />
            <path
              fill="#005180"
              d="M16.5,0H12.55l-.12.19c-.08.14-.17.27-.26.4a10.17,10.17,0,0,0-.94,1.74c-.32.74-.71,1.77-1.25,3.21l-.29.75c-.16.48-.3.88-.43,1.26s-.24.69-.35,1l1.57.47a10.77,10.77,0,0,1,2.8,1.25,1.58,1.58,0,0,1,.61.71,1.31,1.31,0,0,1-.16,1.22,2.62,2.62,0,0,1-.58.57,18.18,18.18,0,0,1-1.77,1c-.94.41-3.46,1.49-4,1.72l-.48.19c0,.08,0,.18-.07.29l0,.18c-.1.45-.24,1-.08,1.13s.07,0,.21,0A4,4,0,0,0,8,17.16c.64-.2,2.8-.95,4.09-1.41l1.93-.7,2-.71c1-.4,1.78-.62,2.15-.73a.68.68,0,0,0,.2-.06.77.77,0,0,1,.25,0,.42.42,0,0,1,.23.8h0l-.23.11c-.41.19-1.18.55-2,.91-1.22.52-2.13.89-2.67,1.11l-.17.07c-3,1.25-4,1.62-6.14,2.33a4.12,4.12,0,0,1-1.3.27h0a1.33,1.33,0,0,1-.88-.29,1.43,1.43,0,0,1-.53-1.24A5.67,5.67,0,0,1,5,16.46l-.89.32c-.65.22-.81.3-1.17.41s-.69-.25-.7-.67c0-.2.22-.36.37-.43l2.14-.82L5.37,15a2,2,0,0,1,.07-.22c.37-1.1.73-2.22,1.53-4.66.07-.21.2-.6.36-1.06L4.48,8.33,2.1,7.73a4.1,4.1,0,0,1-.94-.35.31.31,0,0,1,.18-.55,4.71,4.71,0,0,1,1.58.23c1.1.24,1.62.36,3.21.76l1.49.39c.26-.78.59-1.8,1.11-3.4C9.88,1.19,10.28.46,10.56.05l0,0H3.5A3.52,3.52,0,0,0,0,3.5v13A3.51,3.51,0,0,0,3.5,20h13A3.51,3.51,0,0,0,20,16.5V3.5A3.52,3.52,0,0,0,16.5,0ZM5.12,1.59a1.66,1.66,0,0,1,.47.71,1.75,1.75,0,0,1,0,1,1.34,1.34,0,0,0,0,.35A.71.71,0,0,0,5.62,4s.14,0,.18-.12a.5.5,0,0,0,0-.18A1,1,0,0,1,6.22,3a1,1,0,0,0,.42,1.1,1.78,1.78,0,0,1,.84,1.64A1.3,1.3,0,0,1,7,6.57a1.44,1.44,0,0,1-1.28.19,1.92,1.92,0,0,1-1.4-1.41c-.39-1,.89-3.37-.1-4.26A1.48,1.48,0,0,1,5.12,1.59Z"
            />
            <path
              fill="#005180"
              d="M7.22,14.28l1.44-.56c.71-.29,2.08-.81,3.12-1.35a4.26,4.26,0,0,0,.81-.48.59.59,0,0,0,.3-.43.47.47,0,0,0-.2-.41,4.08,4.08,0,0,0-.93-.5c-.84-.34-1-.45-1.57-.64s-1.07-.36-1.56-.49l-.3,1,0,.07C8,11.68,7.58,12.93,7.22,14.28Z"
            />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" className="shortLogo" width="20px" height="20px">
            <path
              fill="#005180"
              d="M16.5,0H12.55l-.12.19c-.08.14-.17.27-.26.4a10.17,10.17,0,0,0-.94,1.74c-.32.74-.71,1.77-1.25,3.21l-.29.75c-.16.48-.3.88-.43,1.26s-.24.69-.35,1l1.57.47a10.77,10.77,0,0,1,2.8,1.25,1.58,1.58,0,0,1,.61.71,1.31,1.31,0,0,1-.16,1.22,2.62,2.62,0,0,1-.58.57,18.18,18.18,0,0,1-1.77,1c-.94.41-3.46,1.49-4,1.72l-.48.19c0,.08,0,.18-.07.29l0,.18c-.1.45-.24,1-.08,1.13s.07,0,.21,0A4,4,0,0,0,8,17.16c.64-.2,2.8-.95,4.09-1.41l1.93-.7,2-.71c1-.4,1.78-.62,2.15-.73a.68.68,0,0,0,.2-.06.77.77,0,0,1,.25,0,.42.42,0,0,1,.23.8h0l-.23.11c-.41.19-1.18.55-2,.91-1.22.52-2.13.89-2.67,1.11l-.17.07c-3,1.25-4,1.62-6.14,2.33a4.12,4.12,0,0,1-1.3.27h0a1.33,1.33,0,0,1-.88-.29,1.43,1.43,0,0,1-.53-1.24A5.67,5.67,0,0,1,5,16.46l-.89.32c-.65.22-.81.3-1.17.41s-.69-.25-.7-.67c0-.2.22-.36.37-.43l2.14-.82L5.37,15a2,2,0,0,1,.07-.22c.37-1.1.73-2.22,1.53-4.66.07-.21.2-.6.36-1.06L4.48,8.33,2.1,7.73a4.1,4.1,0,0,1-.94-.35.31.31,0,0,1,.18-.55,4.71,4.71,0,0,1,1.58.23c1.1.24,1.62.36,3.21.76l1.49.39c.26-.78.59-1.8,1.11-3.4C9.88,1.19,10.28.46,10.56.05l0,0H3.5A3.52,3.52,0,0,0,0,3.5v13A3.51,3.51,0,0,0,3.5,20h13A3.51,3.51,0,0,0,20,16.5V3.5A3.52,3.52,0,0,0,16.5,0ZM5.12,1.59a1.66,1.66,0,0,1,.47.71,1.75,1.75,0,0,1,0,1,1.34,1.34,0,0,0,0,.35A.71.71,0,0,0,5.62,4s.14,0,.18-.12a.5.5,0,0,0,0-.18A1,1,0,0,1,6.22,3a1,1,0,0,0,.42,1.1,1.78,1.78,0,0,1,.84,1.64A1.3,1.3,0,0,1,7,6.57a1.44,1.44,0,0,1-1.28.19,1.92,1.92,0,0,1-1.4-1.41c-.39-1,.89-3.37-.1-4.26A1.48,1.48,0,0,1,5.12,1.59Z"
            />
            <path
              fill="#005180"
              d="M7.22,14.28l1.44-.56c.71-.29,2.08-.81,3.12-1.35a4.26,4.26,0,0,0,.81-.48.59.59,0,0,0,.3-.43.47.47,0,0,0-.2-.41,4.08,4.08,0,0,0-.93-.5c-.84-.34-1-.45-1.57-.64s-1.07-.36-1.56-.49l-.3,1,0,.07C8,11.68,7.58,12.93,7.22,14.28Z"
            />
          </svg>
        </Navbar.Brand>
        {env && !isMobileOnly && <Badge bg="danger">{env}</Badge>}
        <Nav className="main-nav">
          <Nav.Link
            as={NavLink}
            to="/"
            active={location.pathname === "/"}
            state={{ bypassScrollRestore: true }}
          >
            <div className="link-body">
              <OverlayTrigger
                show={tooltipShowStatus}
                placement="bottom"
                popperConfig={popperConfig}
                overlay={(props) => (
                  <Tooltip id="home-tooltip" {...props}>
                    Начало
                  </Tooltip>
                )}
              >
                <span>
                  <FaHome />
                </span>
              </OverlayTrigger>
              <span className="nav-link-label">Начало</span>
            </div>
          </Nav.Link>
          <SearchMenu />
          <Visible when={not(isLogged())}>
            <DocumentsMenuAnonymous />
          </Visible>
          <Visible when={isLogged()}>
            <DocumentsMenu />
          </Visible>
          <Visible when={hasPrivilege(Privileges.VIEW_COLLECTIONS)}>
            {!isMobileOnly && (
              <Nav.Link
                as={ProtectedNavLink}
                to={"/collections"}
                active={location.pathname.startsWith("/collections")}
              >
                <div className="link-body">
                  <OverlayTrigger
                    placement="bottom"
                    popperConfig={popperConfig}
                    show={tooltipShowStatus}
                    overlay={(props) => (
                      <Tooltip id="collections-tooltip" {...props}>
                        Колекции
                      </Tooltip>
                    )}
                  >
                    <span>
                      <FaBookOpen />
                    </span>
                  </OverlayTrigger>{" "}
                  <span className="nav-link-label">Колекции</span>
                </div>
              </Nav.Link>
            )}
          </Visible>
          <StatsMenu />

          {!isMobileOnly && (
            <Nav.Link
              as={NavLink}
              to={"/bulletins"}
              active={location.pathname.startsWith("/bulletins")}
            >
              <div className="link-body">
                <OverlayTrigger
                  placement="bottom"
                  popperConfig={popperConfig}
                  show={tooltipShowStatus}
                  overlay={(props) => (
                    <Tooltip id="bulletins-tooltip" {...props}>
                      Бюлетин
                    </Tooltip>
                  )}
                >
                  <span>
                    <FaNewspaper />
                  </span>
                </OverlayTrigger>{" "}
                <span className="nav-link-label">Бюлетин</span>
              </div>
            </Nav.Link>
          )}
          <Visible when={hasPrivilege(Privileges.EDIT_USERS)}>
            {!isMobileOnly && (
              <Nav.Link
                as={ProtectedNavLink}
                to={"/admin/users"}
                active={location.pathname.startsWith("/admin")}
              >
                <div className="link-body">
                  <OverlayTrigger
                    placement="bottom"
                    popperConfig={popperConfig}
                    show={tooltipShowStatus}
                    overlay={(props) => (
                      <Tooltip id="admin-tooltip" {...props}>
                        Администрация
                      </Tooltip>
                    )}
                  >
                    <span>
                      <FaLock />{" "}
                    </span>
                  </OverlayTrigger>{" "}
                  <span className="nav-link-label">Администрация</span>
                </div>
              </Nav.Link>
            )}
          </Visible>
        </Nav>

        {(!isMobileOnly || !accessToAskLexebra) && (
          <Nav className="justify-content-end flex-shrink-0 user-menu">
            <Visible when={isLogged()}>
              <Nav.Item>
                <UserMenu />
              </Nav.Item>
            </Visible>
            <Visible when={not(isLogged())}>
              {!isMobileOnly && (
                <Nav.Item className="landing-button-section">
                  <PrimaryButton onClick={() => window.open("https://www.lexebra.com")}>
                    <FaRegArrowAltCircleRight />
                    &nbsp;&nbsp;www.lexebra.com
                  </PrimaryButton>
                </Nav.Item>
              )}
              <Nav.Item className="help-section">
                <PrimaryButton
                  onClick={() => setShowLoginModal(true, { cancelable: true })}
                  submitting={false}
                >
                  Вход
                </PrimaryButton>
              </Nav.Item>
            </Visible>
          </Nav>
        )}
      </Navbar>
    </>
  );
};

const HeaderMobile = ({ children }) => {
  const { setShowLoginModal } = useAuth();
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);

  return (
    <>
      <Navbar className={`user-bar-mobile top`}>
        <Navbar.Brand href="/">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 20" className="shortLogo">
            <path fill="#005180" d="M25.27,2.23h1.65V16.35h8.7v1.42H25.27Z" />
            <path fill="#005180" d="M49.34,16.35v1.42h-11V2.23H49V3.65H40V9.18h8v1.4H40v5.77Z" />
            <path
              fill="#005180"
              d="M62.74,17.77l-5-6.84-5,6.84H50.84l5.91-8L51.22,2.23h1.89l4.68,6.35,4.66-6.35h1.78l-5.51,7.5,5.93,8Z"
            />
            <path fill="#005180" d="M78,16.35v1.42H67V2.23H77.68V3.65h-9V9.18h8v1.4h-8v5.77Z" />
            <path
              fill="#005180"
              d="M94.86,13.62c0,2.64-2,4.15-5.84,4.15H81.88V2.23h6.7C92,2.23,94,3.7,94,6.23a3.61,3.61,0,0,1-2.38,3.53A3.7,3.7,0,0,1,94.86,13.62Zm-11.34-10V9.22h5c2.48,0,3.93-1,3.93-2.82S91,3.59,88.47,3.59Zm9.7,9.89c0-2-1.49-2.9-4.22-2.9H83.52v5.84H89C91.73,16.42,93.22,15.53,93.22,13.48Z"
            />
            <path
              fill="#005180"
              d="M109.22,17.77l-3.55-5a9.86,9.86,0,0,1-1.24.07h-4.18v4.93H98.61V2.23h5.82c3.95,0,6.35,2,6.35,5.33a4.81,4.81,0,0,1-3.56,4.88l3.8,5.33Zm-.09-10.21c0-2.49-1.64-3.91-4.75-3.91h-4.13v7.79h4.13C107.49,11.44,109.13,10,109.13,7.56Z"
            />
            <path
              fill="#005180"
              d="M124.4,13.62h-8.65l-1.87,4.15h-1.71l7.11-15.54h1.62L128,17.77h-1.73Zm-.6-1.33-3.72-8.35-3.73,8.35Z"
            />
            <path
              fill="#005180"
              d="M16.5,0H12.55l-.12.19c-.08.14-.17.27-.26.4a10.17,10.17,0,0,0-.94,1.74c-.32.74-.71,1.77-1.25,3.21l-.29.75c-.16.48-.3.88-.43,1.26s-.24.69-.35,1l1.57.47a10.77,10.77,0,0,1,2.8,1.25,1.58,1.58,0,0,1,.61.71,1.31,1.31,0,0,1-.16,1.22,2.62,2.62,0,0,1-.58.57,18.18,18.18,0,0,1-1.77,1c-.94.41-3.46,1.49-4,1.72l-.48.19c0,.08,0,.18-.07.29l0,.18c-.1.45-.24,1-.08,1.13s.07,0,.21,0A4,4,0,0,0,8,17.16c.64-.2,2.8-.95,4.09-1.41l1.93-.7,2-.71c1-.4,1.78-.62,2.15-.73a.68.68,0,0,0,.2-.06.77.77,0,0,1,.25,0,.42.42,0,0,1,.23.8h0l-.23.11c-.41.19-1.18.55-2,.91-1.22.52-2.13.89-2.67,1.11l-.17.07c-3,1.25-4,1.62-6.14,2.33a4.12,4.12,0,0,1-1.3.27h0a1.33,1.33,0,0,1-.88-.29,1.43,1.43,0,0,1-.53-1.24A5.67,5.67,0,0,1,5,16.46l-.89.32c-.65.22-.81.3-1.17.41s-.69-.25-.7-.67c0-.2.22-.36.37-.43l2.14-.82L5.37,15a2,2,0,0,1,.07-.22c.37-1.1.73-2.22,1.53-4.66.07-.21.2-.6.36-1.06L4.48,8.33,2.1,7.73a4.1,4.1,0,0,1-.94-.35.31.31,0,0,1,.18-.55,4.71,4.71,0,0,1,1.58.23c1.1.24,1.62.36,3.21.76l1.49.39c.26-.78.59-1.8,1.11-3.4C9.88,1.19,10.28.46,10.56.05l0,0H3.5A3.52,3.52,0,0,0,0,3.5v13A3.51,3.51,0,0,0,3.5,20h13A3.51,3.51,0,0,0,20,16.5V3.5A3.52,3.52,0,0,0,16.5,0ZM5.12,1.59a1.66,1.66,0,0,1,.47.71,1.75,1.75,0,0,1,0,1,1.34,1.34,0,0,0,0,.35A.71.71,0,0,0,5.62,4s.14,0,.18-.12a.5.5,0,0,0,0-.18A1,1,0,0,1,6.22,3a1,1,0,0,0,.42,1.1,1.78,1.78,0,0,1,.84,1.64A1.3,1.3,0,0,1,7,6.57a1.44,1.44,0,0,1-1.28.19,1.92,1.92,0,0,1-1.4-1.41c-.39-1,.89-3.37-.1-4.26A1.48,1.48,0,0,1,5.12,1.59Z"
            />
            <path
              fill="#005180"
              d="M7.22,14.28l1.44-.56c.71-.29,2.08-.81,3.12-1.35a4.26,4.26,0,0,0,.81-.48.59.59,0,0,0,.3-.43.47.47,0,0,0-.2-.41,4.08,4.08,0,0,0-.93-.5c-.84-.34-1-.45-1.57-.64s-1.07-.36-1.56-.49l-.3,1,0,.07C8,11.68,7.58,12.93,7.22,14.28Z"
            />
          </svg>
        </Navbar.Brand>
        {env && !isMobileOnly && <Badge bg="danger">{env}</Badge>}

        <Nav className="ms-auto user-menu">
          <Visible when={isLogged()}>
            <Nav.Item>
              <UserMenu />
            </Nav.Item>
          </Visible>
          <Visible when={not(isLogged())}>
            <Nav.Item className="help-section">
              <PrimaryButton
                onClick={() => setShowLoginModal(true, { cancelable: true })}
                submitting={false}
              >
                Вход
              </PrimaryButton>
            </Nav.Item>
          </Visible>
        </Nav>
      </Navbar>
    </>
  );
};

export default Header;
