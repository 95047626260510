import { useCallback } from "react";
import useSWRInfinite from "swr/infinite";
import useRequest, { SearchApiResponse } from "../api";
import { config } from "../constants";
import { useAuth } from "../contexts/auth.context";
import { Chat } from "../models/Chat.model";
import { JudgementAct } from "../models/JudgementAct.model";
import { NewsArticle } from "../models/NewsArticle.model";
import { Summary } from "../models/Summary.model";
import SummaryTopic from "../models/SummaryTopic.model";

export const useFocusedArticles = () => {
  const getKey = useCallback(
    (pageIndex: number, previousPageData: SearchApiResponse<NewsArticle> | null) => {
      if (pageIndex === 0) {
        return `${config.url}/api/home/focused-articles`;
      }
      return previousPageData?._links.next.href || null;
    },
    []
  );
  const result = useSWRInfinite<SearchApiResponse<NewsArticle>>(getKey);
  const totalCount = result.data ? result.data[0].page.totalElements : undefined;
  const complete =
    result.data === undefined ||
    totalCount === 0 ||
    result.data[0].page.totalPages === result.data.length;
  return { ...result, hasMore: !complete, totalCount };
};

export const useHiglightedArticles = () => {
  const getKey = useCallback(
    (pageIndex: number, previousPageData: SearchApiResponse<NewsArticle> | null) => {
      if (pageIndex === 0) {
        return `${config.url}/api/home/highlighted-articles`;
      }
      return previousPageData?._links.next.href || null;
    },
    []
  );
  const result = useSWRInfinite<SearchApiResponse<NewsArticle>>(getKey);
  const totalCount = result.data ? result.data[0].page.totalElements : undefined;
  const complete =
    result.data === undefined ||
    totalCount === 0 ||
    result.data[0].page.totalPages === result.data.length;
  return { ...result, hasMore: !complete, totalCount };
};

export const useFocusedActs = () => {
  return useRequest<JudgementAct[]>("/home/focused-acts");
};

export const useFocusedChats = () => {
  return useRequest<Chat[]>("/home/focused-chats");
};

export const useSummariesTimeline = (topic?: SummaryTopic) => {
  const topicId = topic?.id;
  const { isLoginSessionActive } = useAuth();
  const getKey = useCallback(
    (pageIndex: number, previousPageData: SearchApiResponse<Summary> | null) => {
      if (pageIndex === 0) {
        return `${config.url}/api/home/summaries-timeline?page=0&logged=${isLoginSessionActive()}${
          topicId ? `&topic=${topicId}` : ""
        }`;
      }
      return previousPageData?._links.next.href || null;
    },
    [topicId, isLoginSessionActive]
  );
  const result = useSWRInfinite<SearchApiResponse<Summary>>(getKey);
  const totalCount = result.data ? result.data[0].page.totalElements : undefined;
  const complete =
    result.data === undefined ||
    totalCount === 0 ||
    result.data[0].page.totalPages === result.data.length;
  return { ...result, hasMore: !complete, totalCount };
};
