import dayjs from "dayjs";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import { FaHistory } from "react-icons/fa";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Collapse from "../../components/Collapse";
import { withAuth } from "../../contexts/auth.context";
import { useStatsActYear } from "../../services/stats.services";
import AppPageLayout from "../DocumentPage/app-page-layout";
import StatsActsEditorCareChart from "./stats-acts-editor-care-chart";
import { dayjsRange } from "./stats-acts-month-page";
import StatsActsSccChart from "./stats-acts-scc-chart";
import StatsActsTable from "./stats-acts-table";
import "./stats-page.scss";

interface StatsPageProps {
  year: string;
}

const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const StatsActsYearPage = () => {
  const params = useParams();
  const year = params.year as string;
  const currentYear = new Date().getFullYear();

  return (
    <AppPageLayout
      title={"Статистика: Актове - годишна"}
      className="stats-page"
      copyrighted
      navigation={
        <Container
          fluid
          className="filters-sidebar"
          style={{
            boxShadow: "2px 4px 8px var(--search-page-landing-page-container-border-shadow)",
          }}
        >
          <Collapse icon={<FaHistory />} label="Години">
            <ListGroup
              variant="flush"
              style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
            >
              {range(currentYear, currentYear - 10, -1).map((filterYear) => (
                <ListGroupItem
                  key={filterYear}
                  style={{
                    borderLeft:
                      "3px solid " +
                      (year.toLowerCase() === filterYear.toString()
                        ? "var(--history-page-active-filter)"
                        : "transparent"),
                  }}
                >
                  <Link to={`/stats/acts/${filterYear}`}>{filterYear}</Link>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Collapse>
        </Container>
      }
    >
      <Container fluid className="m-0">
        <StatsPageComponent year={year} />
      </Container>
    </AppPageLayout>
  );
};

const StatsPageComponent: React.FC<StatsPageProps> = ({ year }) => {
  const monthEntries = useStatsActYear(year);
  const monthEntriesNoGaps = monthEntries
    ? [
        ...dayjsRange(
          dayjs(`${year}/1`, "YYYY/M"),
          dayjs(`${parseInt(year) + 1}/1`, "YYYY/M"),
          [1, "month"],
          "YYYY/MM"
        ),
        year.toString(),
      ].map((period) => {
        return (
          monthEntries!.find((entry) => entry.period === period) || {
            period,
            type: period === year ? "year" : "month",
            role: period === year ? "total" : "item",
          }
        );
      })
    : undefined;

  const intl = useIntl();

  return (
    <div style={{ marginBottom: "5px" }}>
      <Card
        style={{
          boxShadow: "2px 4px 8px var(--search-page-landing-page-container-border-shadow)",
        }}
      >
        <Container className="ps-0">
          <h4 className="mt-3 mb-5 me-4">{year}</h4>
          <StatsActsSccChart
            monthStats={monthEntriesNoGaps}
            xAxisLabel={intl.formatMessage({ id: "stats.label.month" })}
          />
          <StatsActsEditorCareChart
            monthStats={monthEntriesNoGaps}
            xAxisLabel={intl.formatMessage({ id: "stats.label.month" })}
          />
        </Container>
        <Container>
          <StatsActsTable
            monthStats={monthEntriesNoGaps}
            periodLabel={intl.formatMessage({ id: "stats.label.month" })}
          />
        </Container>
      </Card>
    </div>
  );
};

export default withAuth(StatsActsYearPage);
