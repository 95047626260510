import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router";
import { PrimaryButton } from "../../components/Button/button";
import { useLatestBulletin } from "../../services/bulletin-services";
import "./focused-section.css";

const DailyBulletin = () => (
  <ErrorBoundary FallbackComponent={DailyBulletinErrorFallback}>
    <DailyBulletinContent />
  </ErrorBoundary>
);

const DailyBulletinErrorFallback = () => {
  return <div />;
};

const DailyBulletinContent = () => {
  const bulletin = useLatestBulletin();
  const navigate = useNavigate();
  return bulletin ? (
    <PrimaryButton
      onClick={() => navigate(`/bulletins/${bulletin.id}`)}
      submitting={false}
      style={{ width: "100%", justifyContent: "center" }}
    >
      {bulletin.title}
    </PrimaryButton>
  ) : null;
};

export const PUBLISH_DATE_FORMAT = "YYYY-MM-DD";

export default DailyBulletin;
