import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";
import "dayjs/locale/bg";
import { StrictMode, Suspense, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { lazily } from "react-lazily";
import { Route, Routes, useLocation } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import { fetcher } from "./api";
import App from "./App";
//import { ManageScroll } from "./components/ManageScroll";
import NotFound from "./components/NotFound";
import AuthContextProvider, { useAuth } from "./contexts/auth.context";
import DocumentContextProvider from "./contexts/document.context";
import LoadingProgressContextProvider from "./contexts/loading-progress.context";
import SearchContextProvider from "./contexts/search.context";
import SelctedActContextProvider from "./contexts/selected-act.context";
import "./index.css";
import AdminPage from "./pages/AdminPage/admin-page";
import BulletinEditPage from "./pages/BulletinPage/bulletin-edit-page";
import BulletinViewPage from "./pages/BulletinPage/bulletin-view-page";
import EditorCollectionViewPageLoading from "./pages/EditorCollectionPage/editor-collection-view-page-loading";
import HomePage from "./pages/HomePage";
import JudgementActViewPage from "./pages/JudgementPage/judgement-view-page";
import JudgementActViewPageLoading from "./pages/JudgementPage/judgement-view-page-loading";
import ChatViewPage from "./pages/LexebraGPTPage/chat-view-page";
import NewsArticleViewPage from "./pages/NewsArticlePage/news-article-view-page";
import NewsArticleViewPageLoading from "./pages/NewsArticlePage/news-article-view-page-loading";
import PasswordResetRequestPage from "./pages/password-reset-page";
import ProfilePage from "./pages/ProfilePage/profile-page";
import SearchPage from "./pages/SearchPage";
import SearchLanding from "./pages/SearchPage/SearchLanding";
import StatsActsMonthPage from "./pages/StatsPage/stats-acts-month-page";
import StatsActsYearPage from "./pages/StatsPage/stats-acts-year-page";
import StatsEditorsPage from "./pages/StatsPage/stats-editors-page";
import SubscriptionConfirmationPage from "./pages/subscription-confirmation-page";
import SubscriptionPageLoading from "./pages/SubscriptionPage/subscription-page-loading";
import SummaryViewPage from "./pages/SummaryViewPage/summary-view-page";
import UserPageLoading from "./pages/UserPage/user-page-loading";
import {
  EditorCollectionEditPage,
  EditorCollections,
  EditorCollectionViewPage,
} from "./secondary-edit-chunk";
import messagesbg from "./translations/bg.json";

const {
  SummaryEditPage,
  JudgementEditPage,
  NewsArticleEditPage,
  UserEditPage,
  SubscriptionEditPage,
} = lazily(() => import(/* webpackChunkName: "secondary-edit" */ "./secondary-edit-chunk"));

const { default: HistoryPage } = lazily(() =>
  import(/* webpackChunkName: "secondary-view" */ "./pages/HistoryPage/history-page")
);

const JudgementActEditPageLazy = () => {
  return (
    <Suspense fallback={<JudgementActViewPageLoading />}>
      <JudgementEditPage />
    </Suspense>
  );
};

const NewsArticleEditPageLazy = () => {
  return (
    <Suspense fallback={<NewsArticleViewPageLoading />}>
      <NewsArticleEditPage />
    </Suspense>
  );
};

const UserEditPageLazy = () => {
  return (
    <Suspense fallback={<UserPageLoading />}>
      <UserEditPage />
    </Suspense>
  );
};

const SubscriptionEditPageLazy = () => {
  return (
    <Suspense fallback={<SubscriptionPageLoading />}>
      <SubscriptionEditPage />
    </Suspense>
  );
};

const EditorCollectionEditPageLazy = () => {
  return (
    <Suspense fallback={<EditorCollectionViewPageLoading />}>
      <EditorCollectionEditPage />
    </Suspense>
  );
};

const EditorCollectionViewPageLazy = () => {
  return (
    <Suspense fallback={<EditorCollectionViewPageLoading />}>
      <EditorCollectionViewPage />
    </Suspense>
  );
};

const EditorCollectionsPageLazy = () => {
  return (
    <Suspense fallback={<></>}>
      <EditorCollections />
    </Suspense>
  );
};

dayjs.locale("bg");

const weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const localeData = require("dayjs/plugin/localeData");
dayjs.extend(localeData);

const whitelistedPaths = ["/home", "/sign", "-log"];

const AppComponent = () => {
  const { isLoginSessionActive } = useAuth();

  const fetcher2 = async (args) => {
    if (!isLoginSessionActive() && !whitelistedPaths.find((path) => args.indexOf(path) !== -1)) {
      //setShowLoginModal(true, {successCallback: () => navigate(pathname)});
      return new Promise((resolve, reject) => {
        // we do not resolve the promise in order to keep the suspense in pending state
      });
    } else {
      return fetcher(args);
    }
  };

  const { pathname } = useLocation();
  // useEffect(() => {
  //   post("/logs/navigation", {
  //     type: "navigation",
  //     pathname: pathname,
  //   });
  // }, [pathname]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <SWRConfig
      value={{
        fetcher: fetcher,
        revalidateOnFocus: false,
      }}
    >
      <IntlProvider locale="bg" messages={messagesbg}>
        <SelctedActContextProvider>
          <SearchContextProvider>
            <DocumentContextProvider>
              <LoadingProgressContextProvider>
                <Suspense fallback={<div />}>
                  <App>
                    <Routes className="router" primary={false}>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/collections" element={<EditorCollectionsPageLazy />} />
                      <Route
                        path="/collections/:collectionId"
                        element={<EditorCollectionViewPageLazy />}
                      />
                      <Route
                        path="/collections/:collectionId/edit"
                        element={<EditorCollectionEditPageLazy />}
                      />
                      <Route path="/collections/new" element={<EditorCollectionEditPageLazy />} />
                      <Route path="/search" element={<SearchLanding />} />
                      <Route path="/ask-lexebra" element={<ChatViewPage />} />
                      <Route path="/ask-lexebra/:chatId" element={<ChatViewPage />} />
                      <Route path="/search/:category" element={<SearchPage />} />
                      <Route path="/judgement-acts/:actId" element={<JudgementActViewPage />} />
                      <Route path="/judgement-acts/new" element={<JudgementActEditPageLazy />} />
                      <Route
                        path="/judgement-acts/:actId/edit"
                        element={<JudgementActEditPageLazy />}
                      />
                      <Route
                        path="/judgement-acts/:actId/summaries/new"
                        element={<SummaryEditPage />}
                      />

                      <Route path="/summaries/:summaryId" element={<SummaryViewPage />} />
                      <Route path="/summaries/:summaryId/edit" element={<SummaryEditPage />} />
                      <Route path="/summaries/new" element={<SummaryEditPage />} />
                      <Route path="/news-articles/:articleId" element={<NewsArticleViewPage />} />

                      <Route
                        path="/news-articles/:articleId/edit"
                        element={<NewsArticleEditPageLazy />}
                      />
                      <Route path="/news-articles/new" element={<NewsArticleEditPageLazy />} />
                      <Route
                        path="/confirm/:confirmationId"
                        element={<SubscriptionConfirmationPage />}
                      />
                      <Route
                        path="/password-reset/:resetRequestId"
                        element={<PasswordResetRequestPage />}
                      />
                      <Route path="/stats/acts/:year" element={<StatsActsYearPage />} />
                      <Route path="/stats/acts/:year/:month" element={<StatsActsMonthPage />} />
                      <Route path="/stats/editors/:year/:week" element={<StatsEditorsPage />} />
                      <Route path="/bulletins" element={<BulletinViewPage />} />
                      <Route path="/bulletins/new" element={<BulletinEditPage />} />
                      <Route path="/bulletins/:bulletinId/edit" element={<BulletinEditPage />} />
                      <Route path="/bulletins/:bulletinId" element={<BulletinViewPage />} />

                      <Route path="/user/history/:period" element={<HistoryPage />} />
                      <Route path="/user/profile" element={<ProfilePage />} />
                      <Route path="/admin/:category" element={<AdminPage />} />
                      <Route path="/admin/users/new" element={<UserEditPageLazy />} />
                      <Route path="/admin/users/:userId" element={<UserEditPageLazy />} />
                      <Route
                        path="/admin/subscriptions/new"
                        element={<SubscriptionEditPageLazy />}
                      />
                      <Route
                        path="/admin/subscriptions/:subscriptionId"
                        element={<SubscriptionEditPageLazy />}
                      />
                      <Route path="*" elemment={<NotFound />} />
                    </Routes>
                  </App>
                </Suspense>
              </LoadingProgressContextProvider>
            </DocumentContextProvider>
          </SearchContextProvider>
        </SelctedActContextProvider>
      </IntlProvider>
    </SWRConfig>
  );
};

ReactDOM.render(
  <FpjsProvider
    loadOptions={{
      apiKey: "pyvOfCyMsXceuJnU9BXe",
      region: "eu",
      endpoint: "https://metrics.lexebra.com",
    }}
  >
    <StrictMode>
      <BrowserRouter>
        <AuthContextProvider>
          <AppComponent />
        </AuthContextProvider>
      </BrowserRouter>
    </StrictMode>
  </FpjsProvider>,
  document.getElementById("root")
);

window.onerror = function (msg, url, lineNo, columnNo, error) {
  var clientSideError = {
    type: "error",
    msg: msg,
    url: url,
    lineNumber: lineNo,
    columnNumber: columnNo,
    error: {
      message: error.message,
      stack: error.stack,
      name: error.name,
    },
  };

  // send the error object to the server
  //post("/logs/error", clientSideError);
  console.log(clientSideError);

  return false;
};

// globalHistory.listen(({ location }) => {
//   post("/logs/navigation", {
//     type: "navigation",
//     pathname: location.pathname,
//     state: location.state,
//   });
// });
