import Table from "react-bootstrap/Table";
import Skeleton from "../../../components/Skeleton";
import ResultsTableHeader from "./results-table-header";
import "./results-table.scss";

const ResultsTableLoading: React.FC<{
  count?: number;
  perRowCount?: number;
  showHeader?: boolean;
}> = ({ count = 10, perRowCount = 2, showHeader = true }) => {
  return (
    <div className="results-table-container">
      <Table borderless responsive size="sm" className="results-table">
        {showHeader && <ResultsTableHeader addNewPath="/users/new" sortOptions={[]} />}
        <tbody>
          {Array.from({ length: count }, (_, i) => i + 1).map((idx) => (
            <tr key={idx}>
              <td>
                <Skeleton count={perRowCount} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ResultsTableLoading;
