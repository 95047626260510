import dayjs, { Dayjs } from "dayjs";
import { Accordion, ListGroupItem, Spinner, Table, Tooltip } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import { FaCrown, FaHistory } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from "recharts";
import Collapse from "../../components/Collapse";
import { getInitials } from "../../components/EditorInfo/editor-info";
import Skeleton from "../../components/Skeleton";
import { withAuth } from "../../contexts/auth.context";
import { StatsEditor } from "../../models/StatsEditor.model";
import { useStatsEditors } from "../../services/stats.services";
import { useUserNames } from "../../services/user-services";
import AppPageLayout from "../DocumentPage/app-page-layout";
import "./stats-page.scss";

interface StatsPageProps {
  from: Dayjs;
  to: Dayjs;
  week: string;
}

const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const StatsEditorsPage = () => {
  const params = useParams();
  const year = params.year as string;
  const week = params.week as string;
  const currentYear = new Date().getFullYear();
  const base = dayjs(`${year}/01/01`, "YYYY-MM-DD").week(parseInt(week));
  const from = base.startOf("week");
  const to = base.endOf("week");

  return (
    <AppPageLayout
      title={"Статистика: Редакторска грижа"}
      className="stats-page"
      copyrighted
      navigation={
        <Container
          fluid
          className="filters-sidebar"
          style={{
            boxShadow: "2px 4px 8px var(--search-page-landing-page-container-border-shadow)",
          }}
        >
          <Collapse icon={<FaHistory />} label="Години">
            <Accordion defaultActiveKey={year} flush>
              {range(currentYear, currentYear - 3, -1).map((filterYear) => (
                <Accordion.Item eventKey={filterYear.toString()} key={filterYear}>
                  <Accordion.Header>{filterYear}</Accordion.Header>
                  <Accordion.Body>
                    <ListGroup
                      variant="flush"
                      style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "10px" }}
                    >
                      {range(filterYear === currentYear ? dayjs().week() : 53, 1, -1).map(
                        (filterWeek) => {
                          const filterBase = dayjs(`${filterYear}/01/01`, "YYYY-MM-DD").week(
                            filterWeek
                          );
                          const filterFrom = filterBase.startOf("week");
                          const filterTo = filterBase.endOf("week");
                          return (
                            <ListGroupItem
                              key={filterWeek}
                              style={{
                                paddingLeft: "20px",
                                borderLeft:
                                  "3px solid " +
                                  (year.toLowerCase() === filterYear.toString() &&
                                  parseInt(week) === filterWeek
                                    ? "var(--history-page-active-filter)"
                                    : "transparent"),
                              }}
                            >
                              <Link to={`/stats/editors/${filterYear}/${filterWeek}`}>
                                Седмица {filterWeek}
                              </Link>
                              <span className="text-muted ">
                                {` (${filterFrom.format("DD.MM.YYYY")} - ${filterTo.format(
                                  "DD.MM.YYYY"
                                )})`}
                              </span>
                            </ListGroupItem>
                          );
                        }
                      )}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Collapse>
        </Container>
      }
    >
      <Container fluid className="m-0">
        <StatsPageComponent from={from} to={to} week={week} />
      </Container>
    </AppPageLayout>
  );
};

const StatsPageComponent: React.FC<StatsPageProps> = ({ from, to, week }) => {
  const editorEntries = useStatsEditors(from.format("DD.MM.YYYY"), to.format("DD.MM.YYYY"));
  const editors = useUserNames();

  const intl = useIntl();

  return (
    <div style={{ marginBottom: "5px" }}>
      <h4>
        Седмица {parseInt(week)}: {from.format("DD.MM.YYYY")} - {to.format("DD.MM.YYYY")}
      </h4>
      <Card
        style={{
          boxShadow: "2px 4px 8px var(--search-page-landing-page-container-border-shadow)",
        }}
      >
        <Container className="ps-0">
          <h5 className="mt-3 text-center">
            <FormattedMessage id="stats.header.editors" />
          </h5>
          <div className="stats-container">
            {!editorEntries && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="stats-loading-spinner"
              />
            )}
            <ResponsiveContainer width={"99%"} height={300}>
              <BarChart
                width={500}
                height={300}
                style={{
                  filter: !editorEntries && "blur(3px)",
                }}
                data={(editorEntries || []).filter((m) => m.role === "item")}
                {...{
                  overflow: "visible",
                }}
                margin={{
                  top: 0,
                  right: 30,
                  left: 50,
                  bottom: 10,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey={(entry) =>
                    getInitials(editors.find((editor) => editor.id === entry.id)?.name)
                  }
                />
                <YAxis scale="sqrt" />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="stats.countSummarized"
                  stackId="a"
                  fill="#8884d8"
                  barSize={40}
                  name={intl.formatMessage({ id: "stats.editor.label.summarized" })}
                />
                <Bar
                  dataKey="stats.countHighlighted"
                  stackId="a"
                  fill="#82ca9d"
                  barSize={40}
                  name={intl.formatMessage({ id: "stats.editor.label.highlighted" })}
                />
                <Bar
                  dataKey="stats.countEditorOpinion"
                  stackId="a"
                  fill="#b33dc6"
                  barSize={40}
                  name={intl.formatMessage({ id: "stats.editor.label.editorOpinion" })}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Container>
        <Container>
          {editorEntries ? <StatsActsTable editorStats={editorEntries} /> : <Skeleton count={20} />}
        </Container>
      </Card>
    </div>
  );
};

const StatsActsTable: React.FC<{ editorStats: StatsEditor[] }> = ({ editorStats }) => {
  return (
    <Table className="stats-table">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="stats.label.editor" />
          </th>
          <th>
            <FormattedMessage id="stats.editor.label.summarized" />
          </th>
          <th>
            <FormattedMessage id="stats.editor.label.highlighted" />
          </th>
          <th>
            <FormattedMessage id="stats.editor.label.editorOpinion" />
          </th>
        </tr>
      </thead>
      {editorStats
        .filter((e) => e.role === "item")
        .map((editorStat) => {
          return <StatRow editorStat={editorStat} editorStats={editorStats} />;
        })}
      <tfoot>
        {editorStats
          .filter((e) => e.role === "total")
          .map((editorStat) => {
            return <StatRow editorStat={editorStat} editorStats={editorStats} />;
          })}
      </tfoot>
    </Table>
  );
};

const StatRow: React.FC<{ editorStat: StatsEditor; editorStats: StatsEditor[] }> = ({
  editorStats,
  editorStat,
}) => {
  const editors = useUserNames();
  const topSummarized = Math.max(
    ...editorStats
      .filter((e) => e.role === "item" && e.id !== 3 && e.stats?.countSummarized)
      .map((e) => e.stats!.countSummarized)
  );
  const topHighlighted = Math.max(
    ...editorStats
      .filter((e) => e.role === "item" && e.id !== 3 && e.stats?.countHighlighted)
      .map((e) => e.stats!.countHighlighted)
  );
  const topEditorOpinion = Math.max(
    ...editorStats
      .filter((e) => e.role === "item" && e.id !== 3 && e.stats?.countEditorOpinion)
      .map((e) => e.stats!.countEditorOpinion)
  );
  const summarizedChamp =
    !!topSummarized &&
    editorStat.role !== "total" &&
    editorStat.stats?.countSummarized === topSummarized;
  const highlightedChamp =
    !!topHighlighted &&
    editorStat.role !== "total" &&
    editorStat.stats?.countHighlighted === topHighlighted;
  const editorOpinionChamp =
    !!topEditorOpinion &&
    editorStat.role !== "total" &&
    editorStat.stats?.countEditorOpinion === topEditorOpinion;
  return (
    <tr key={editorStat.id}>
      <td className="capitalize-first">
        {editorStat.role === "total"
          ? "Общо"
          : editors.find((editor) => editor.id === editorStat.id)?.name}
      </td>
      <td className={summarizedChamp ? "stats-champion" : ""}>
        {editorStat.stats?.countSummarized || 0}
        <span>
          <FaCrown />
        </span>
      </td>
      <td className={highlightedChamp ? "stats-champion" : ""}>
        {editorStat.stats?.countHighlighted || 0}
        <span>
          <FaCrown />
        </span>
      </td>
      <td className={editorOpinionChamp ? "stats-champion" : ""}>
        {editorStat.stats?.countEditorOpinion || 0}
        <span>
          <FaCrown />
        </span>
      </td>
    </tr>
  );
};

export default withAuth(StatsEditorsPage);
