import { EntityModel } from "hateoas-hal-types";
import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { useNavigate } from "react-router";
import img_questions from "../../assets/images/thumbnails/questions.png";
import { Chat } from "../../models/Chat.model";
import { useChats } from "../../services/chat-services";
import "./sample-search-cards.scss";

const SampleSearchCards = () => {
  const { data: recent } = useChats("recent");
  const { data: saved } = useChats("saved");
  const { data: focused } = useChats("focused");
  const { data: opened } = useChats("last-opened");
  return (
    <Tab.Container defaultActiveKey={"3"}>
      <Nav variant="pills" className="opinion-tabs no-swipe">
        <Nav.Item>
          <Nav.Link eventKey="3">Избрано от Лексебра</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="0">Последно отваряни</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="1">Най-нови</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2">Запазени</Nav.Link>
        </Nav.Item>
      </Nav>

      <Tab.Content>
        <Tab.Pane eventKey="0">
          <TabPaneContents chats={opened} />
        </Tab.Pane>
        <Tab.Pane eventKey="1">
          <TabPaneContents chats={recent} />
        </Tab.Pane>
        <Tab.Pane eventKey="2">
          <TabPaneContents chats={saved} />
        </Tab.Pane>
        <Tab.Pane eventKey="3">
          <TabPaneContents chats={focused} />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

const TabPaneContents: React.FC<{ chats?: EntityModel<Chat>[] }> = ({ chats }) => {
  const navigate = useNavigate();
  return (
    <div className="sample-search-cards">
      {chats?.map((chat) => (
        <div key={chat.id} className="search-card" onClick={() => navigate(chat.id)}>
          <img src={img_questions} alt="Questions Thumbnail" className="card-thumbnail" />
          <span>
            <span className="card-title">{chat.title}</span>
            <div className="text-muted preview">{chat.query}</div>
          </span>
        </div>
      ))}
    </div>
  );
};

export default SampleSearchCards;
