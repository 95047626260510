import DocumentAccessMode from "./DocumentAccessMode.enum";
import DocumentStatus from "./DocumentStatus.enum";
import { JudgementAct } from "./JudgementAct.model";
import RefData from "./RefData.model";
import SummaryQa from "./SummaryQa.model";

export interface Chat {
  user: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
  };
  id: string;
  title: string;
  query: string;
  saved: boolean;
  status: DocumentStatus;
  accessMode: DocumentAccessMode;
  cost: number;
  createdOn: string;
  timeReading: number;

  responses: ChatResponse[];
}

export enum ChatResponseProgress {
  GENERATING = "GENERATING",
  RECEIVING = "RECEIVING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  ERROR_RETRYABLE = "ERROR_RETRYABLE",
  TIMEOUT = "TIMEOUT",
}

export const isOnError = (value?: ChatResponseProgress) => {
  return (
    value === ChatResponseProgress.ERROR ||
    value === ChatResponseProgress.ERROR_RETRYABLE ||
    value === ChatResponseProgress.TIMEOUT
  );
};

export const isRetryableError = (value: ChatResponseProgress) => {
  return value === ChatResponseProgress.ERROR_RETRYABLE || value === ChatResponseProgress.TIMEOUT;
};

export interface ChatDocuments {
  id: string;
  questions: { score: number; relatedQuestion: SummaryQa }[];
  acts: { score: number; relatedJudgementAct: JudgementAct }[];
}

export interface ChatResponse {
  content: string;
  model: RefData;
  cost: number;
  createdOn: Date;
  id: string;
  progress: ChatResponseProgress;
  timeElapsedInMs: number;
}
