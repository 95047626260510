import dayjs from "dayjs";
import { BiTimeFive } from "react-icons/bi";
import { FormattedDate } from "react-intl";
import PreloadingLink from "../../components/PreloadingLink";
import Skeleton from "../../components/Skeleton";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import { prefetchChat } from "../../services/chat-services";
import { useFocusedChats } from "../../services/home-services";
import AccessIndicator from "./access-indicator";
import { PUBLISH_DATE_FORMAT } from "./focused-section";

const FocusedChats: React.FC = () => {
  const { data: chats } = useFocusedChats();
  return chats ? (
    <div>
      {chats.map((chat) => (
        <div key={chat.id}>
          <div className="item-heading">
            <PreloadingLink
              preload={(cache) => prefetchChat(chat.id, cache)}
              to={`/ask-lexebra/${chat.id}`}
              state={{ from: "/" }}
              protect={chat.accessMode === DocumentAccessMode.PAID}
            >
              {chat.title}
            </PreloadingLink>
            <AccessIndicator mode={chat.accessMode} />
          </div>
          <div className="text-muted preview">{chat.query}</div>
          <div className="item-details">
            <FormattedDate
              value={dayjs(chat.createdOn, PUBLISH_DATE_FORMAT).toDate()}
              month="long"
              day="2-digit"
            />
            {chat.timeReading ? (
              <>
                &nbsp;-&nbsp;
                <BiTimeFive />
                &nbsp;{chat.timeReading} мин. за преглед
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <>
      <Skeleton count={10} />
    </>
  );
};

export default FocusedChats;
