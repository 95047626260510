import {
  all,
  hasName,
  hasPrivilege,
  oneOf,
  Privileges,
  Visible,
} from "../../contexts/auth.context";
import { useSummaryContext } from "../../contexts/summary.context";
import DocumentStatus, { isPublishedStatus } from "../../models/DocumentStatus.enum";
import { printSummaryTitle } from "../../models/Summary.model";
import {
  fetchSummaryPdf,
  fetchSummaryRtf,
  updateSummaryStatus,
} from "../../services/summary-services";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";
import DocumentCollectionActions from "../DocumentPage/document-collection-actions";
import DocumentEditAction from "../DocumentPage/document-edit-action";
import DocumentStatusAction from "../DocumentPage/document-status-action";
import SummarySearchAction from "./summary-search-action";

const SummaryViewPageMenu = () => {
  const { summary } = useSummaryContext();

  return (
    <DocumentActionsContainer
      document={{
        id: summary.id,
        title: printSummaryTitle(summary),
      }}
    >
      <SummarySearchAction />
      <Visible
        when={oneOf(
          all(hasName(summary.editor?.name), () => !isPublishedStatus(summary.status)),
          all(
            hasPrivilege(Privileges.EDIT_ALL_SUMMARIES),
            () => !summary.restrictedEdit,
            () => summary.status === DocumentStatus.DRAFT
          ),
          hasPrivilege(Privileges.PROTECT_SUMMARIES)
        )}
      >
        <DocumentEditAction />
      </Visible>
      <DocumentStatusAction
        document={summary}
        setStatus={(status) => updateSummaryStatus(summary.id, status)}
      />
      <DocumentCollectionActions
        showPrintExport
        showRtfExport
        dataToExport={async (options) => {
          return options.rtf
            ? (await fetchSummaryRtf(summary.id)).data
            : (await fetchSummaryPdf(summary.id)).data;
        }}
        accessMode={summary.accessMode}
      />
      <DocumentCloseAction />
    </DocumentActionsContainer>
  );
};

export default SummaryViewPageMenu;
