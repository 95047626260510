// search-menu.js
import React from "react";
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDeviceSelectors } from "react-device-detect";
import { FaSearch } from "react-icons/fa";
import { useLocation } from "react-router";
import { useSWRConfig } from "swr";
import { ReactComponent as AiIcon } from "../../assets/ai.svg";
import { Privileges, ProtectedLink, useAuth } from "../../contexts/auth.context";
import { useSearchContext } from "../../contexts/search.context";
import { prefetchDocumentStats } from "../../services/document-services";
import { popperConfig } from "./header";

const SearchMenu = () => {
  const { lastSearchHref } = useSearchContext();
  const { cache } = useSWRConfig();

  const location = useLocation();
  const [{ isMobileOnly }] = useDeviceSelectors(window.navigator.userAgent);
  const auth = useAuth();

  const accessToAskLexebra = auth.hasPrivilige(Privileges.EDIT_SUMMARIES);
  const tooltipShowStatus = false;
  return (
    <>
      {accessToAskLexebra && (
        <Nav.Link
          as={ProtectedLink}
          to={"ask-lexebra"}
          active={location.pathname.startsWith("/ask-lexebra")}
          className="focused"
        >
          <div className="link-body">
            <OverlayTrigger
              placement="bottom"
              popperConfig={popperConfig}
              show={tooltipShowStatus}
              overlay={(props) => (
                <Tooltip id="ask-lexebra-tooltip" {...props}>
                  Попитай Лексебра
                </Tooltip>
              )}
            >
              <span>
                <AiIcon width="1.1rem" height="1.1rem" />{" "}
              </span>
            </OverlayTrigger>

            <span className="nav-link-label">Попитай</span>
          </div>
        </Nav.Link>
      )}
      <Nav.Link
        as={ProtectedLink}
        to={lastSearchHref || "search"}
        active={location.pathname.startsWith("/search")}
        onClick={async () => {
          const params =
            lastSearchHref && lastSearchHref.indexOf("?") > -1
              ? lastSearchHref.substring(lastSearchHref.indexOf("?"))
              : "";
          await prefetchDocumentStats(new URLSearchParams(params), cache);
        }}
      >
        <div className="link-body">
          <OverlayTrigger
            placement="bottom"
            popperConfig={popperConfig}
            show={tooltipShowStatus}
            overlay={(props) => (
              <Tooltip id="search-tooltip" {...props}>
                Търсене
              </Tooltip>
            )}
          >
            <span>
              <FaSearch style={{ rotate: "90deg" }} />{" "}
            </span>
          </OverlayTrigger>

          <span className="nav-link-label">Търсене</span>
        </div>
      </Nav.Link>
    </>
  );
};

export default SearchMenu;
