import { EntityModel } from "hateoas-hal-types";
import { FunctionComponent } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Chat } from "../../models/Chat.model";
import DocumentAccessMode from "../../models/DocumentAccessMode.enum";
import DocumentStatus from "../../models/DocumentStatus.enum";
import { updateChatStatus } from "../../services/chat-services";
import DocumentAction from "../DocumentPage/document-action";
import DocumentActionsContainer from "../DocumentPage/document-actions-container";
import DocumentCloseAction from "../DocumentPage/document-close-action";
import DocumentCollectionActions from "../DocumentPage/document-collection-actions";
import DocumentEditAction from "../DocumentPage/document-edit-action";
import DocumentStatusAction from "../DocumentPage/document-status-action";
import { ChatViewPageMode } from "./chat-view-page";

interface ChatViewPageActionsProps {
  chat?: EntityModel<Chat>;
  setMode: (mode: ChatViewPageMode) => void;
  mode: ChatViewPageMode;
}

const ChatViewPageActions: FunctionComponent<ChatViewPageActionsProps> = ({
  chat,
  setMode,
  mode,
}) => {
  const navigate = useNavigate();
  const deleteChat = async (chatId: string, status: DocumentStatus) => {
    try {
      await updateChatStatus(chatId, status);
      toast.success("Въпросът беше изтрит успешно");
    } catch (error) {
      console.error(error);
      toast.error("Възникна грешка при изтриването на въпроса");
    }
  };
  return mode === "view" ? (
    <DocumentActionsContainer document={chat}>
      {chat && <DocumentEditAction handler={() => setMode("edit")} />}
      {chat && (
        <DocumentStatusAction
          document={chat}
          setStatus={(status) => updateChatStatus(chat.id, status)}
        />
      )}
      {chat && (
        <DocumentAction
          handler={() =>
            deleteChat(chat.id, DocumentStatus.DELETED).then(() => navigate("/ask-lexebra"))
          }
          icon={<FaRegTrashAlt />}
          label="Изтрий"
        />
      )}

      {chat && <DocumentCollectionActions accessMode={DocumentAccessMode.PAID} />}
      <DocumentCloseAction handler={() => navigate("/ask-lexebra")} />
    </DocumentActionsContainer>
  ) : null;
};

export default ChatViewPageActions;
