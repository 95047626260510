import React, { useEffect, useState } from "react";
import { FaRegArrowAltCircleRight, FaRegCheckCircle } from "react-icons/fa";
import { ChatResponse } from "../../models/Chat.model";
import "./loadingIndicator.css";

const loadingMessages = [
  "Анализиране на поставения въпрос",
  "Идентифициране на релевантни становища",
  "Анализиране на приложимата съдебна практика",
  "Оценка на евентуални противоречия",
  "Систематизиране на правния контекст и неговото значение",
  "Формулиране на обоснован правен извод",
  "Изготвяне на окончателен и аргументиран отговор",
];

const interval = 2000;

const LoadingIndicator: React.FC<{ onlyLast?: boolean; response: ChatResponse }> = ({
  onlyLast = false,
  response,
}) => {
  const [currentIndex, setCurrentIndex] = useState(
    Math.min(Math.floor(response.timeElapsedInMs / interval), loadingMessages.length - 1)
  );

  useEffect(() => {
    if (currentIndex < loadingMessages.length - 1) {
      const messageTimeout = setTimeout(() => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, interval);

      return () => clearTimeout(messageTimeout);
    }
  }, [currentIndex]);

  return (
    <div className="card-text loading-indicator">
      {loadingMessages
        .slice(onlyLast ? currentIndex : 0, onlyLast ? currentIndex + 1 : loadingMessages.length)
        .map((message, index) => (
          <div
            key={index}
            className={`d-flex gap-1 align-items-center ${
              currentIndex === index || onlyLast ? "current" : ""
            } ${currentIndex >= index || onlyLast ? "" : "text-muted"}`}
          >
            {currentIndex === index || onlyLast ? (
              <FaRegArrowAltCircleRight />
            ) : currentIndex < index ? (
              <FaRegCheckCircle className="invisible" />
            ) : (
              <FaRegCheckCircle />
            )}
            <span>{message}</span>
          </div>
        ))}
    </div>
  );
};

export default LoadingIndicator;
