import { FunctionComponent } from "react";
import { RiFocus3Fill, RiFocus3Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { hasPrivilege, Privileges, Visible } from "../../contexts/auth.context";
import DocumentStatus from "../../models/DocumentStatus.enum";
import DocumentAction from "../DocumentPage/document-action";

interface DocumentStatusActionProps {
  setStatus: (status: DocumentStatus) => Promise<any>;
  document: {
    id: string;
    status: DocumentStatus;
  };
}

const DocumentStatusAction: FunctionComponent<DocumentStatusActionProps> = ({
  document,
  setStatus,
}) => {
  const updateStatus = async (status: DocumentStatus) => {
    try {
      await setStatus(status);
      toast.success("Успешно променихте статуса на документа");
    } catch (error) {
      console.error(error);
      toast.error("Възникна грешка при промяната на статуса на документа");
    }
  };
  return (
    <Visible when={hasPrivilege(Privileges.EDIT_ALL_SUMMARIES)}>
      {document.status === DocumentStatus.PUBLIC && (
        <DocumentAction
          handler={() => updateStatus(DocumentStatus.FOCUSED)}
          icon={<RiFocus3Line />}
          label='Сложи "На фокус"'
        />
      )}
      {document.status === DocumentStatus.FOCUSED && (
        <DocumentAction
          handler={() => updateStatus(DocumentStatus.PUBLIC)}
          icon={<RiFocus3Fill />}
          label='Премахни "На фокус"'
        />
      )}
    </Visible>
  );
};

export default DocumentStatusAction;
