import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { UseSearchRequestReturn } from "../../../api";
import LabelValue from "../../../components/label-value";
import PreloadingLink from "../../../components/PreloadingLink";
import { Privileges, useAuth } from "../../../contexts/auth.context";
import { useSearchContext } from "../../../contexts/search.context";
import SummaryQa from "../../../models/SummaryQa.model";
import { prefetchSummary, summaryHref, useQuestions } from "../../../services/summary-services";
import ResultsTable from "../ResultsTable/results-table";
import ResultsTableHeader from "../ResultsTable/results-table-header";

const QuestionsResults = () => {
  const results = useQuestions();

  return <SummaryQuestionsResults results={results} />;
};

type ResultsViewProps = {
  results: UseSearchRequestReturn<SummaryQa>;
};

const SummaryQuestionsResults: React.FC<ResultsViewProps> = ({ results }) => {
  const { lastSearchHref } = useSearchContext();

  const { opened, addOpened } = useSearchContext();

  const auth = useAuth();

  const { formatMessage } = useIntl();

  return (
    <>
      <ResultsTable
        results={results}
        itemToSelection={(qa: SummaryQa) => ({
          _id: qa.id,
          favouritesId: qa.summary.id,
          title: qa.shortTitle,
          href: "",
        })}
        header={(params) => <ResultsTableHeader {...params} />}
        allowBulkLoad={auth.hasPrivilige(Privileges.EDIT_SUMMARIES)}
        row={(qa) => (
          <ResultsTable.ResultTableRow>
            <PreloadingLink
              to={summaryHref(`${qa.summary.id}#${qa.id}`)}
              state={{
                from: lastSearchHref,
              }}
              preload={(cache) => prefetchSummary(qa.summary.id, cache)}
              onClick={() => addOpened(qa.id)}
              className={`questions-mode ${opened.indexOf(qa.id) > -1 ? "visited" : ""}`}
            >
              {qa.question}
            </PreloadingLink>
            {qa.caseType &&
              qa.caseType.code !== "CRIMINAL" &&
              qa.caseType.code !== "CRIMINAL_PRIVATE" && (
                <LabelValue label={formatMessage({ id: "view-judgement.labels.proceedingBy" })}>
                  {qa.proceeding?.shortName}
                </LabelValue>
              )}
            {qa.actDate && /\d{4}\/\d{2}\/\d{2}/.test(qa.actDate) && (
              <LabelValue label={formatMessage({ id: "view-judgement.labels.actDate" })}>
                {dayjs(qa.actDate, "YYYY/MM/DD").format("DD.MM.YYYY")} г.
              </LabelValue>
            )}
            <ResultsTable.RowIndicators
              item={{
                ...qa,
                status: qa.summary.status,
              }}
            />
            <ResultsTable.RowHighlights item={qa} />
          </ResultsTable.ResultTableRow>
        )}
      />
    </>
  );
};

export default QuestionsResults;
